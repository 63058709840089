.guest_image {
    border-radius: 50%;
    width: 75px;
    height: 75px;
}

.guest_name {
    font-weight: 700;
    color: var(--color-2);
    font-size: 19px;
}

.guest_post {
    font-weight: 500;
    width: 80%;
    color: var(--color-2);
    opacity: 0.6;
}