.ct_page1 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 200vh;
  width: 100%;
  background: url("../Assets//Backgrounds/Team.webp");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
}

.contact1 {
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: relative;
  top: 0;
  left: 0;
}
.contact-card {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  max-width: 240px;
  align-items: center;
  margin: 20px;
}
.contact-card button i {
  margin-left: 3px;
}
.contact_icon {
  border: none;
  outline: none;
  align-self: center;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  font-size: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-2);
  color: var(--color-1);
}

.contact_heading {
  font-weight: 700;
  font-size: 19px;
  margin: 25px 0px 25px 0px;
  text-align: center;
}

.contact_form {
  background-color: var(--color-3);
  padding: 50px;
  color: var(--color-2);
  width: 60%;
  display: flex;
  flex-direction: column;
}

.contactus_heading {
  font-size: 40px;
  font-weight: 800;
  text-align: center;
}

.head_image {
  width: 350px;
  height: 500px;
  margin: 50px;
}
