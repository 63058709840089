.multi_head {
    width: 50%;
    font-size: 40px;
    text-align: center;
    font-weight: 700;
    font-family: "Merriweather", sans-serif;
}

.navigate {
    font-size: 33px;
    font-weight: 700;
    color: var(--color-1);
    text-decoration: none;
    text-align: right;
}