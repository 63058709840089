.ec_page1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background: url("../Assets//Backgrounds/Econnect.webp");
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
}
.ec_page2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 350vh;
    width: 100%;
    background: url("../Assets//Backgrounds/P5.webp");
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
}
.ec_page3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 140vh;
    width: 100%;
    background: url("../Assets//Backgrounds/Perks.webp");
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
}
.what_heading {
    font-size: 45px;
    font-weight: 700;
    margin: 50px;
    align-self: center;
    font-family: "Merriweather", sans-serif;
}

.what_content {
    margin: 50px;
    width: 50%;
    font-size: 25px;
}

.vision_heading {
    font-size: 40px;
    font-weight: 700;
    margin: 50px;
    align-self: center;
    color: var(--color-2);
    font-family: "Merriweather", sans-serif;
}

.vision_content {
    margin: 50px;
    width: 50%;
    font-size: 23px;
}

.connect_buttons {
    outline: none;
    border: none;
    font-size: 30px;
    background-color: var(--color-1);
    padding: 25px;
    color: var(--color-2);
    cursor: pointer;
}

.perk_icon {
    width: 200px;
    height: 200px;
    display: flex;
    align-self: center;
    justify-content: center;
    align-content: center;
    
}

.perk_head {
    font-size: 25px;
    font-weight: 700;
    text-align: center;
    margin-top: 20px;
}

.perk_content {
    font-size: 22px;
    width: 350px;
}

.perk_template {
    margin: 20px;
    box-shadow:  0px 0px 6px rgb(0, 0, 0);
    border-radius: 10px;
    height: 275px;
    background-color: var(--color-2);
    transform-style: preserve-3d;
    transition: all 0.8s ease;
}

.perk_template:hover{
    transform: rotateY(180deg);
}
.front{
     /* -webkit-backface-visibility: hidden; */
     backface-visibility: hidden;
     display: flex;
     flex-direction: column;
}
.back{
     /* -webkit-backface-visibility: hidden; */
     backface-visibility: hidden;
     display: flex;
     flex-direction: column;
    transform: rotateY(180deg);
    margin-top: -175px;
    padding: 5px;
    font-size: 20px;
}
