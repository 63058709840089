.footer {
  background-color: var(--color-6);
  color: var(--color-2);
  display: flex;
  justify-content: space-between;
  padding: 50px 50px 100px 50px;
  font-size: 16px;
}

.footer_heading {
  font-size: 25px;
  font-weight: 700;
}

.fa-facebook,
.fa-twitter,
.fa-pinterest,
.fa-instagram {
  background: transparent;
  color: var(--color-1);
}

.fa1 {
  font-size: 25px;
  color: var(--color-2);
  width: 30px;
  height: 30px;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
  padding: 10px 10px 10px 10px;
  margin: 10px 10px 10px 10px;
}

.fa1:hover {
  color: var(--color-3);
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.footer_subnames {
  color: var(--color-5);
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.footer_subnames:hover {
  color: var(--color-3);
  text-decoration: none;
}

@media screen and (max-width: 725px) {
  .footer {
    flex-direction: column;
  }
  .spacing {
    margin-bottom: 100px;
  }
}
