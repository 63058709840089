  .newmaincontainer {
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 40%), 0 6px 20px 0 rgb(0 0 0 / 40%);
    width: 95%;
    max-height: 90%;
    margin-top: 20px;
    margin-left: 20px;
    display: flex;
    z-index: 0;
    flex-direction: column;
  } 
  .newuppercontainer {
    background-image: radial-gradient(circle, #5b5454, #504a4a, #453f3f, #3b3636, #312c2c);
    border-radius: 10px;
    height: 45%;
    width: 100%;
    z-index: 2;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    display: flex;
    flex-basis: 180px;
    justify-content: center;
    }
  .lowercontainer{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    overflow: auto;
  }
  .newimage {
    align-self: center;
    margin-right: 10px;
    margin : 3%;
    color: white;
    height: 125px;
    width: 125px;
    border-radius: 50%;    
    
    
    background-size: cover;
    background-position: center;
  }
  .rightimage {
    margin-left: 2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
  .newname {
    font-size: 250%;
    font-weight: 800;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: white;
}
  .newdesignation {
    margin-top: 1%;
    font-size: 110%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 600;
    color: #999;
  }
  .lowerbox {
    padding: 20px;
    z-index: 1;
    height: fit-content;
    /* overflow: auto; */
    width: 90%;
    margin-top: -10px;
    margin-bottom: 10px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 40%), 0 6px 20px 0 rgb(0 0 0 / 40%);
    background-color: white;
    /* transform: translateY(-5%); */
    border-radius: 5px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
      
  }
  
  .namehead {
    margin: 5px;
    margin-left: 7px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    color: #999;
    font-weight: 750;
    font-size: 17px;
  }
  .info-card{
      padding: 5px;
      flex-basis: 12;
  }
  .boxy {
    width: 100%;
    height: 40px;
    text-decoration: none;
    border-width: 0px;
    border-radius: 4px;
    text-align: center;
    outline: none;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 19px;
    background-color: none;
    font-weight: 700;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 5px;
  }  

  @media(max-width: 400px) 
  {
    .newimage{
      height: 100px;
      width: 100px;
    }
    .newname{
      font-size: 180%;
    }
  }
