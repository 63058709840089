
.outercontainer{
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
    height: 90%;
    width: 90%;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
}
.uppercontainer{
    background-image: radial-gradient(circle, #5b5454, #504a4a, #453f3f, #3b3636, #312c2c);
    border-radius: 10px;
    width: 100%;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    flex-basis: 180px;
    display: flex;
    justify-content: center;
}
.image{
    margin-top:2%;
    margin-right: 2%;
    height: 125px;
    width: 125px;
    border-radius: 75px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
    
    background-size: cover;
}   
.names{
    margin-left: 2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.namenew{
    font-size: 250%;
    font-weight: 800;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: white;
}
.namenewlow{
    margin-top: 1%;
    font-size: 110%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 600;
    color: #999;
}
.cl1{
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
    overflow: auto;
}
.boxnew{
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 20%);
    padding: 10px;
    margin: 10px;
    /* width: 50%; */
    min-height: 166px;
    background-color: white;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 180%;
    font-weight: 800;
    color: black;
    border-radius: 10px;
    flex-basis: 4;
    width: 30%;
    max-width: 287px;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.titlenew {
    text-align: center;
    font-size: 30px;
}
.number{
    background-color: #999;
    align-self: center;
    text-align: center;
    border-radius: 35px;
    padding: 0px 30px;
    margin: 10px 0 5px 0;
}
.baaki{
    margin-top: 5px;
    transform: translateY(-15%);
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 170%;
    font-weight: 800;
}
.note {
    padding: 5px;
}
.note_head {

   color: red;
   font-size: 20px; 
   font-weight: 800;
   text-decoration: underline;
}
.note_content {
    display: flex;
    flex-direction: column;
    height: fit-content;
    }
.points {
   font-weight: 600;
   color:rgb(0, 48, 119);
   margin:2px;
}
@media(max-width: 740px){
    .boxnew{
        max-width:280px;
    }
}
