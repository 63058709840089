.gm_1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background: url("./klh1.jpg");
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
}
.fb_live{
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height:100vh;
    background: url("./hgj.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding:50px;
}
.rules {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height:fit-content;
    background: url("./lpo.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding:50px;
}
.rules_head {
    font-size: 30px;
    font-family: 'Roboto';
    font-weight: 900;
    color: rgb(0, 16, 109);
    padding:20px;
    border-radius: 5px;
}
.rules_content {
    color: white;
    padding:20px;
}
.h {
    font-size: 25px;
    color: red;
    font-weight: 800;
}
.w {
    color: var(--color-3);
    font-size: 15px;
}

.spon {
    font-size: 25px;
    font-weight: 900;
    color: red;
}
.schedule {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 100%;
    padding:10px;
    background-image: url("./xyz.webp");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
}
.sched_head {
  font-weight: 800;
  font-size: 40px;
  padding: 10px;
  background-color: rgba(240, 248, 255);
  margin-bottom:20px;
  margin-top: 10px;
  border-radius: 10px;
}
.tables {
    margin:40px;
    background-color: #002754;
}
tr {
    border: 2px solid white;
}
td , th {
    padding:10px;
    text-align: center;
}
th {
    font-size: 30px;
    padding:20px;
    color:#082cea;
    font-family: Roboto;
}
.t {
   
    margin-bottom: 20px;
}
td {
    color: white;
    font-size: 20px;
    font-weight: 700;
}