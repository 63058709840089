.lp_page1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background: url("../Assets/Backgrounds/Summit.webp");
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
}
.lp_page2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 100%;
    background: url("../Assets/Backgrounds/Black.webp");
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
}
.lp_page8 {
    display: flex;
    height: 200vh;
    width: 140%;
    background: url("../Assets/Backgrounds/S1.webp");
    background-repeat: no-repeat;
}

.logo_fly {
    width: 100%;
    height: auto;
}

.guest_heading {
    color: var(--color-2);
    font-weight: 700;
    font-size: 45px;
    padding-top: 100px;
}

.sub_heading {
    color: var(--color-1);
    font-size: 30px;
    font-weight: 700;
}

.sponsor_options {
    margin: 20px;
    cursor: pointer;
    font-size: 40px;
}

.sponsor_head {
    text-align: center;
    font-weight: 600;
    font-size: 40px;
}

.sponsor_name {
    text-align: center;
    font-weight: 500;
    font-size: 30px;
}

.sponsor_image {
    width: 400px;
    height: 400px;
    align-self: center;
    align-content: center;
    text-align: center;
}
