.gr_page1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background: url("../../Assets/Backgrounds/GR.webp");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.9;
}
.gr_page4 {
  display: none;
  flex-direction: column;
  height: 180vh;
  width: 100%;
  background: url("../../Assets/Backgrounds/About.webp");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
}
.gr_page5 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5vh 0;
  width: 100%;
  background: url("../../Assets/Backgrounds/P3.webp");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
}
.gr_page2 {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  height: 180vh;
  width: 100%;
  background: url("../../Assets/Backgrounds/P2.webp");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
}
.gr_page3 {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding-top: 30px;
  padding-bottom: 30px;
  height: 100%;
  width: 100%;
  background: url("../../Assets/Backgrounds/Rules.webp");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
}

.reg_head {
  text-align: center;
  font-weight: 800;
  font-family: "Poppins", serif;
  font-size: 35px;
  margin-bottom: 30px;
}

.gr_input {
  border: none;
  outline: none;
  box-shadow: 0px 0px 3px var(--color-1);
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  width: 400px;
  align-self: center;
}

.gr_input:focus {
  box-shadow: 0px 0px 4px var(--color-3);
}

.dots {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  background-color: var(--color-3);
  color: var(color-1);
  border: 3px solid var(--color-3);
}

.section_head {
  margin-top: 30px;
  color: var(--color-2);
  font-size: 20px;
}

.line {
  height: 5px;
  background-color: var(--color-3);
  width: 150px;
  align-self: center;
  animation: all 3s;
}

.book_next,
.book_next:focus {
  width: 110px;
  padding: 10px 20px;
  border-radius: 10px;
  margin-top: 30px;
  margin-left: 100px;
  background-color: var(--color-3);
  box-shadow: 0px 0px 6px var(--text-color-3);
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
}

.book_next:hover {
  box-shadow: 0px 0px 6px var(--color-1);
}

.btn_div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-image: url("../../Assets/GR.webp");
  background-size: cover;
}

.heading_main {
  color: var(--color-2);
  font-family: "Raleway", sans-serif;
  font-size: 62px;
  margin-bottom: 80px;
  font-weight: 600;
}

.btn,
.btn:focus {
  cursor: pointer;
  background-color: white;
  width: 300px;
  height: 70px;
  border-radius: 10px;
  font-size: 25px;
  font-weight: 600;
  background-size: 100% 100%;
  border: none;
  outline: none;
}

.btn:hover {
  opacity: 0.6;
}

.Description {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
}

.Description1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex: 1;
  background-color: #00b4d8;
  border-radius: 60px;
  margin: 5px;
  box-shadow: 0px 0px 6px black;
}

.Description2 {
  display: flex;
  box-shadow: 0px 0px 6px black;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex: 1;
  text-align: center;
  background-color: #caf0f8;
  border-radius: 60px;
  margin: 5px;
}

.dropbtn {
  border: none;
  outline: none;
  box-shadow: 0px 0px 3px var(--color-1);
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  width: 400px;
  justify-content: center;
  justify-self: center;
  background-color: transparent;
}

.inputfield {
  width: 500px;
}

.adding {
  background-color: var(--color-2);
}

.gr_head {
  font-weight: 600;
  margin-bottom: 10px;
  text-align: center;
}
.gr-info {
  display: flex;
  flex-direction: column;
  background-color: white;
  opacity: 0.8;
  width: 450px;
  border-radius: 30px;
  margin-top: 30px;
  padding: 20px;
}
.gr-info div {
  line-height: 1.6;
  font-size: 20px;
}
.schema-faq-code {
  border: 1px solid #dedee0;
  border-radius: 10px;
  width: 532px;
  background-color: #fefbf9;
  overflow: hidden;
}
.schema-faq-code .faq-q {
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  padding: 12px 56px 12px 12px;
  line-height: 1.4;
  cursor: pointer;
  position: relative;
  border-bottom: 1px solid #dedee0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.faq-q:after {
  content: "+";
  position: absolute;
  top: 50%;
  right: 0;
  width: 56px;
  text-align: center;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-weight: bold;
  color: #000;
  font-size: 20px;
}
.faq-q.faq-q-open:after {
  content: "-";
}
.faq-a {
  margin: 0;
  padding: 12px;
  background-color: #fff;
  font-size: 14px;
  line-height: 1.4;
  border-bottom: 1px solid #dedee0;
  display: none;
}
.schema-faq-code .faq-question:last-child .faq-a {
  border-bottom: 0px;
}

.reg-closed {
  width: 100%;
  font-size: 50px;
}
@media screen and (max-width: 910px) {
  .gr-info {
    width: 80%;
  }
  .reg-closed {
    font-size: 30px;
  }
  .gr-info div {
    font-size: 19px;
  }
}
@media screen and (max-width: 625px) {
  .schema-faq-code {
    width: 90%;
  }
  .gr-info div {
    font-size: 18px;
  }
}
@media screen and (max-width: 525px) {
  .gr-info {
    width: 90%;
  }
  .reg-closed {
    font-size: 25px;
  }
  .gr-info div {
    font-size: 17px;
  }
}
