@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merienda:wght@400;700&display=swap");
body {
  padding: 0;
  margin: 0;
  --color-1: #000000;
  --color-2: #ffffff;
  --color-3: #08c2e9;
  --color-7: #3a82d7;
  --color-4: rgb(51, 51, 51);
  --color-5: rgb(125, 133, 93);
  --color-6: rgb(30, 30, 30);
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  font-family: "Merriweather", sans-serif;
}

.change_btn {
  border: none;
  outline: none;
  border-radius: 50%;
  width: 75px;
  height: 75px;
  font-size: 35px;
  cursor: pointer;
  align-self: center;
  margin-left: -50px;
  background-color: var(--color-2);
}

.change_btn:hover {
  background-color: var(--color-1);
  opacity: 0.9;
  color: var(--color-2);
}

h1 {
  font-size: 40px;
  color: var(--color-2);
}

@keyframes typewriter {
  0%,
  100% {
    width: 0;
  }
  20%,
  80% {
    width: 13.5em;
  }
}

@keyframes caret {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.text_1 {
  overflow: hidden;
  white-space: nowrap;
  animation-name: typewriter;
  display: inline-block;
  position: relative;
  animation-duration: 6s;
  margin-bottom: 20px;
  font-size: 35px;
  animation-timing-function: steps(25, end);
  animation-iteration-count: infinite;
}

.text_1::after {
  position: absolute;
  right: 0;
  animation: caret infinite;
  animation-duration: 1s;
  animation-timing-function: steps(1, end);
}

.page1_sub {
  color: var(--color-2);
  margin-top: -30px;
  width: 25em;
}
@media screen and (max-width: 525px) {
  .text_1 {
    font-size: 32px;
    display: block;
    line-height: 1;
    margin-bottom: 35px;
  }
}
@media screen and (max-width: 420px) {
  .text_1 {
    font-size: 28px;
  }
}
