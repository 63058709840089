.title1 {
  position: relative;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background: url("../Assets//Backgrounds/Home.webp");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
}

.title2 {
  display: flex;
  flex-wrap: wrap;
  min-height: 130vh;
  padding-bottom: 30px;
  width: 100%;
  background: url("../Assets//Backgrounds/Black.webp");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
}

.wrapper {
  position: absolute;
  left: 10vw;
  display: flex;
  flex-direction: column;
  margin: 10px 10px 10px 0px;
  justify-content: center;
}

.home_headings {
  text-align: center;
  font-weight: 800;
  font-size: 40px;
  color: var(--color-4);
  margin-bottom: 40px;
}

.home_networking {
  background-color: var(--color-6);
  color: var(--color-2);
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 55px 25px 120px 90px;
  margin-bottom: 100px;
}

.home_side_network {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.net_title {
  font-size: 40px;
  font-weight: 800;
  color: var(--color-4);
  opacity: 1.6;
}

.network_btn,
.network_btn:focus {
  border: none;
  outline: none;
  background-color: var(--color-1);
  color: var(--color-2);
  width: max-content;
  padding: 25px;
  margin-top: 50px;
  font-size: 25px;
  cursor: pointer;
  border-radius: 20px;
}

.network_btn:hover {
  box-shadow: 0px 0px 6px black;
}

.net_image {
  width: 100%;
  height: auto;
}

.initiative {
  border-radius: 10px;
  width: 200px;
  height: 200px;
  margin: 100px 100px 5px 100px;
  cursor: pointer;
}

@media screen and (max-width: 1225px) {
  .initiative {
    width: 150px;
    height: 150px;
  }
}

@media screen and (max-width: 725px) {
  .title2 {
    height: auto;
  }
  .wrapper,
  .page1_sub {
    width: 20em;
  }
}
@media screen and (max-width: 525px) {
  .wrapper {
    left: 5vw;
  }
}
