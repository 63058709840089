.sp_page1 {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-top: 30px;
        padding-bottom: 30px;
        height: 100vh;
        width: 100%;
        background-image: url("../../Assets/Backgrounds/Sponsor.webp");
        background-size: cover;
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
}

.sp_page2 {
        display: flex;
        flex-direction: column;
        justify-content:center;
        align-items:center;
        min-height: 100vh;
        padding-bottom: 30px;
        width: 100%;
        background: url("../../Assets../../Assets/Backgrounds/SP\ 1.webp");
        background-size: cover;
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
}
.sp_page3 {
        display: flex;
        flex-direction: column;
        justify-content:center;
        align-items:center;
        min-height: 100vh;
        padding-bottom: 30px;
        width: 100%;
        background: url("./a2.jfif");
        background-size: cover;
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
}