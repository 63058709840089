.se_page{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
    width: 100%;
    filter: blur(10%);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.se_page.p1{
    background: url("./valo.jpg");
    background-position: center;
    background-size: cover;
    filter: blur(10%)
}
.se_page.p2{
    background: url("../../Assets/Backgrounds/quiz.webp");
    background-position: center;
    background-size: cover;
}
.se_page.p3{
    background: url("./safl.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

