.sp_p1 {
    position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
    width: 100%;
    background: url("../../Assets/Backgrounds/B3.webp");
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    animation: change 40s infinite alternate;
}
.sp_p2 {
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    min-height: 100vh;
    padding-bottom: 30px;
    width: 100%;
    background: url("../../Assets../../Assets/Backgrounds/SP\ 1.webp");
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
  }
  .sp_p3 {
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    min-height: 100vh;
    padding-bottom: 30px;
    width: 100%;
    background: url("../../Assets/Backgrounds/ConferenceSpeaker.webp");
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
  }

  @keyframes change {
    0% {
        background: url("../../Assets/Backgrounds/B1.webp");
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
    }
    25% {
        background: url("../../Assets/Backgrounds/B2.webp");
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
    }
    50% {
        background: url("../../Assets/Backgrounds/B3.webp");
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
    }
    75% {
        background: url("../../Assets/Backgrounds/B4.webp");
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
    }
    100% {
        background: url("../../Assets/Backgrounds/B2.webp");
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
    }
}
.reg_head{
    color:white;
    font-family: "Roboto, sans-serif" ;
    background-color: black ;
    padding:20px ;
    border-radius:20px ;
    margin:15px;
}
p{
    background-color: rgba(0, 0, 139, 0.5);
    font-family:Georgia, 'Times New Roman', Times, serif;
}