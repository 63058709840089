@import url("https://fonts.googleapis.com/css?family=Montserrat:400,800");

.lg_page1 {
  display: flex;
    flex-direction: column;
    height: 120vh;
    width: 100%;
    background: url("./Components/kkk.jpeg");
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
}

h1 {
  font-weight: bold;
  margin: 0;
}

h2 {
  text-align: center;
}

p {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}

span {
  font-size: 12px;
}

a {
  color: #333;
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0;
}

button {
  border-radius: 20px;
  border: 1px solid #ff4b2b;
  background-color: #ff4b2b;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}

button:active {
  transform: scale(0.95);
}

button:focus {
  outline: none;
}

button.ghost {
  background-color: transparent;
  border-color: #ffffff;
}

form {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
}

input,
input:focus {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
  outline: none;
}

.container {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  z-index: 0;
  width: 768px;
  max-width: 100%;
  min-height: 540px;
}
#container {
  margin: 13% auto;
  margin-bottom: 0px;
}
.form-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
  display: flex;
  flex-direction: column;
}

.sign-in-container {
  left: 0;
  width: 50%;
  z-index: 2;
}

.container.right-panel-active .sign-in-container {
  transform: translateX(100%);
}

.sign-up-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
  overflow: auto;
}

.container.right-panel-active .sign-up-container {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: show 0.6s;
}

@keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.container.right-panel-active .overlay-container {
  transform: translateX(-100%);
}

.overlay {
  background: #ff416c;
  background: -webkit-linear-gradient(to right, #ff4b2b, #ff416c);
  background-image: radial-gradient(
    circle,
    #5b5454,
    #504a4a,
    #453f3f,
    #3b3636,
    #312c2c
  );

  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.container.right-panel-active .overlay {
  transform: translateX(50%);
}

.overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.overlay-left {
  transform: translateX(-20%);
}

.container.right-panel-active .overlay-left {
  transform: translateX(0);
}

.overlay-right {
  right: 0;
  transform: translateX(0);
}

.container.right-panel-active .overlay-right {
  transform: translateX(20%);
}

.social-container {
  margin: 20px 0;
}

.social-container a {
  border: 1px solid #dddddd;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
}

footer {
  background-color: #222;
  color: #fff;
  font-size: 14px;
  bottom: 0;
  position: fixed;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 999;
}

footer p {
  margin: 10px 0;
}

footer i {
  color: red;
}

footer a {
  color: #3c97bf;
  text-decoration: none;
}
.headx {
  font-size: 25px;
  margin-bottom: 20px;
  font-weight: bold;
  align-self: center;
  margin-top: 15px;
}
.signin {
  font-size: 25px;
  margin-bottom: 20px;
  font-weight: bold;
}
.button2 {
  margin-top: 20px;
  transition: 0.2s;
  background-color: #444;
  border-color: #555;
  margin-bottom: 10px;
}
.button2:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
}

.button2:focus {
  background-color: white;
  border-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
  color: #555;
}
input[type="file"] {
  display: none;
}
label {
  width: 284px;
  background-color: #f1eeee;
  height: 35px;
  padding: 9px;
  font-size: 14px;
  font-weight: 100;
  margin-top: 8px;
  color: #777;
  text-align: left;
  padding-left: 15px;
  border-radius: 2px;
}
.error{
  color: red;
  background-color: transparent;
}
.forgotpw{
  background-color: transparent;
  color: red;
}
.forgotpw:hover{
  text-decoration: underline;
  cursor: pointer; 
}
.scrollmsg1,.scrollmsg2,.scrollmsg3,.scrollmsg4{
  background-color: transparent;
  color: red;
}
.scrollmsg1{
  transform: translate(50%,-700%);
}
.scrollmsg2{
  transform: translate(-50%,-600%);
}
.scrollmsg3{
  transform: translate(-50%,-15%);
}
.scrollmsg4{
  transform: translate(50%,-100%);
}
@media (max-width:550px){
  .container{
    transform: scale(.75);
    overflow: auto;
  }
  .form-container{
    width: 100%;
    padding-top: 0;
  }
  .overlay-container{ 
    width: 100%;
    transform: translateX(50%);
  }
  .container.right-panel-active .overlay-container {
    transform: translateX(-50%);
  }
  .container.right-panel-active .overlay {
    transform: translateX(50%);
  }
}
@media (min-width:550px)
{
  .scrollmsg1,.scrollmsg2,.scrollmsg3,.scrollmsg4{
    display: none;
  }
}