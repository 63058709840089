.bar {
  list-style: none;
  text-align: right;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: flex-end;
  height: 90px;
  z-index: 1;
  transition: background-color 0.3s ease-in-out;
}

.name {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  margin-left: 110px;
  margin-top: 15px;
  font-size: 175%;
  width: auto;
  font-weight: 900;
  font-family: "Poppins", sans-serif;
  color: var(--color-2);
  z-index: 2;
}

.logo {
  position: fixed;
  top: 5px;
  left: -30px;
  width: 275px;
  height: 100px;
  z-index: 2;
}

.link {
  text-decoration: none;
  padding: none;
  margin: none;
  color: white;
  display: inline-block;
  cursor: pointer;
  font-size: 20px;
  transition: color 0.5s ease-in-out;
}

.link:hover,
.linkInternship:hover {
  color: var(--color-3);
  text-decoration: none;
}
.linkInternship {
  margin: 25px;
  background: var(--color-3);
  border-radius: 20px;
  font-size: 20px;
  padding: 0px 10px 0px 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  transition: all 0.5s ease-in-out;
  text-decoration: none;
  color: white;
}
.linkInternship:hover {
  background-color: #fff;
}

.linkInternship::after {
  display: none !important;
}
.main {
  display: flex;
  flex-direction: column;
}

.navWrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
}
.navWrapper div {
  position: relative;
}
.link:not(.borderline)::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -10px;
  left: 0;
  height: 3px;
  background-color: var(--color-3);
  z-index: 2;
  width: 0%;
  transition: 0.3s ease-in;
}

.borderline:not(.slink)::after {
  /* border-bottom: 3px solid var(--color-3); */
  content: "";
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  height: 3px;
  background-color: var(--color-3);
  z-index: 2;
  width: 100%;
}

@media screen and (max-width: 860px) {
  .link {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .link {
    margin: 25px 5px 5px 5px;
    font-size: 17px;
  }
}

@media (max-width: 525px) {
  .logo {
    display: none;
  }
  .name {
    display: none;
  }
  .linkInternship {
    position: absolute;
    height: 40px;
    left: 10px;
    margin: 25px;
    font-size: 20px;
    background: var(--color-3);
    border-radius: 20px;
    padding: 0px 10px 0px 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    transition: all 0.5s ease-in-out;
    text-decoration: none;
    color: white;
  }
}
