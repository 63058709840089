.launch_events {
  border-radius: 15px;
  width: 300px;
  height: 250px;
}

.levents_heading {
  font-weight: 700;
  color: var(--color-2);
  text-align: center;
  font-size: 25px;
}

.boxes {
  margin: 45px;
}

@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    transform: translatey(-20px);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
}

.e-connect {
  box-sizing: border-box;
  overflow: hidden;
  transform: translatey(0px);
  animation: float 2.5s ease-in-out infinite;
}
.e-connect:hover {
  animation-play-state: paused;
}
@media screen and (max-width: 1030px) {
  .launch_events {
    height: 220px;
    width: 270px;
  }
}
@media screen and (max-width: 585px) {
  .launch_events {
    height: 210px;
    width: 265px;
  }
  .levents_heading {
    font-size: 24px;
  }
}
