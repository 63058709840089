.cm_pg1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background: url("../Assets//Backgrounds/CNM.jfif");
  filter: brightness(70%);
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
}

.cm_pg2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 120vh;
  width: 100%;
  filter: blur(50%);
  background: url("../Assets//Backgrounds/CNM\ 2.webp");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
}

.C_join,
.C_join:focus {
  border: none;
  outline: none;
}
.id_login {
  background-color: var(--color-1);
  margin: 0px;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  padding: 15px 20px;
}
.id_login a {
  height: fit-content;
  margin: 0;
  z-index: 10;
  mix-blend-mode: difference;
}
.id_login::after {
  content: "";
  background-color: white;
  border-radius: 10px;
  height: 100%;
  width: 0;
  padding: 0;
  margin: 0;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.5s;
}
.id_login:hover {
  filter: brightness(100%) !important;
  color: black;
}
.id_login:hover::after {
  width: 100%;
}
