.sidenav {
  width: 40vw;
  z-index: 20;
  background-color: black;
  position: fixed;
  right: 0;
  height: 100%;
  margin-right: 10px;
  margin: 0;
  overflow-x: hidden;
  padding-top: 60px;
}

.logostyle {
  width: 120%;
  margin-left: -30px;
  height: 100px;
}

.namestyle {
  font-size: 30px;
}

.slink {
  padding: 5px;
  margin: 5px;
  text-decoration: none;
  list-style-type: none;
  width: max-content;
  font-size: 25px;
  color: #818181;
  display: flex;
  flex-direction: column;
  transition: 0.3s;
}

.sidelinks:hover {
  color: #f1f1f1;
  text-decoration: none;
}

.closebtn {
  position: absolute;
  top: 20px;
  right: 25px;
  font-size: 30px;
  margin-left: 50px;
  cursor: pointer;
}

.side_button {
  margin: 20px;
  font-size: 30px;
  cursor: pointer;
  z-index: 3;
  width: max-content;
}

.appearance {
  display: none;
  width: 36px;
}

@media screen and (max-width: 860px) {
  .appearance {
    display: block;
  }
}

@media screen and (max-width: 525px) {
  .sidenav {
    width: 70%;
  }
  .logostyle {
    height: 120px;
  }
}

@media screen and (min-width: 525px) {
  .namestyle,
  .logostyle {
    display: none;
  }
}

@media screen and (max-width: 475px) {
  .namestyle {
    font-size: 20px;
  }
  .logostyle {
    height: 90px;
  }
}
