.qz_1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background: url("./kooiz.jpeg");
    filter: blur(70%);
    filter: brightness(90%);
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
}
.text_1.heading{
    color:rgb(0, 0, 0);
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: rgb(185, 178, 247);
    font-weight: bolder;
}
.fb_live{
    display: none; /*convert none to flex when streaming*/
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height:100vh;
    background: url("./kuiz.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding:50px;
}
.live {
    background-color: rgba(240, 248, 255, 0.822);
    font-size: 30px;
    font-family: 'Roboto';
    font-weight: 900;
    color: rgb(0, 16, 109);
    padding:20px;
    border-radius: 5px;
}