.events_pic {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    align-self: center;
    margin-bottom: 40px;
}

.events_h_name {
    text-align: center;
    color: var(--color-4);
    font-weight: 800;
    font-size: 20px;
}

.events_description {
    text-align: center;
    color: var(--color-3);
    font-weight: 600;
}