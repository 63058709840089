.contact_dp {
    border-radius: 50%;
    width: 200px;
    height: 200px;
    align-self: center;
}

.contact_name {
    font-size: 30px;
    text-align: center;
    font-weight: 800;
    color: var(--color-4);
}

.contact_post {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    color: var(--color-4);
}

.contact_number {
    text-align: center;
    font-size: 22px;
    color: var(--color-4);
}