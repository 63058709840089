/* Mobile Nav */

.mobile-nav .nav-checkbox {
  display: none;
}

.mobile-nav .nav-button {
  display: none;
  text-align: center;
  background: red;
  height: 54px;
  width: 54px;
  border-radius: 50%;
  position: fixed;
  top: 34px;
  right: 30px;
  z-index: 2000;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.4);
}
.nav-button i {
  font-size: 24px;
}
.mobile-nav .nav-background {
  display: none;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.9);
  position: fixed;
  top: 42px;
  right: 31px;
  z-index: 1000;
  transition: transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
}
.mobile-nav .nav-contents {
  text-align: center;
  height: 100vh;
  opacity: 0;
  transition: opacity 1.7s;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1500;
}
.mobile-nav .nav-contents .nav-contents-wrapper {
  width: 0;
}
.nav-button i,
.mobile-nav .nav-contents .nav-contents-wrapper,
.mobile-nav .navigation-list {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.mobile-nav .nav-checkbox:checked ~ .nav-background {
  transform: scale(80);
}
.mobile-nav .nav-checkbox:checked ~ .nav-contents {
  width: 100vw;
  display: block;
  opacity: 1;
}
.mobile-nav .nav-checkbox:checked ~ .nav-contents .nav-contents-wrapper {
  width: 100vw;
}
.top {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  /* font-size: ; */
  border-style: solid;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  border-bottom-width: 1px;
  margin: 20px;
  margin-left: 100px;
  margin-right: 100px;

  padding-bottom: 20px;
  text-align: center;
}
.container22 {
  width: 95%;
  margin: 20px auto;
  background-image: linear-gradient(
    to bottom,
    #ffffff,
    #ebebec,
    #d9d7d8,
    #c6c4c5,
    #b4b1b1
  );
  display: flex;
  height: 95%;
  overflow: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
  padding-top: -20px;
}
.lefty {
  width: 40%;
  margin: 5%;
  text-align: center;
  margin-left: 6%;
  margin-right: 3%;
}
.right {
  width: 40%;
  margin: 5%;
  text-align: center;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
  overflow: hidden;
}

/* Track */
::-webkit-scrollbar-track {
  background-image: linear-gradient(
    to bottom,
    #ffffff,
    #ebebec,
    #d9d7d8,
    #c6c4c5,
    #b4b1b1
  );
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 10px;
  transition: 0.2s;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.boxx {
  max-width: 350px;
  background-image: radial-gradient(
    circle,
    #5b5454,
    #504a4a,
    #453f3f,
    #3b3636,
    #312c2c
  );
  margin-bottom: 30px;
  border-radius: 10px;
  padding: 10px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}
.andarka {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  color: white;
}
.head {
  font-size: 30px;
  margin-bottom: 12px;
}
.button1 {
  width: 150px;
  background-color: darkgreen;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 18px;
  border-radius: 20px;
  margin-top: 15px;
  height: fit-content;
  padding: 9px;
  font-weight: 650;
  color: white;
  transition: 0.2s;
  user-select: none;
  border-color: #555;
}
.button1:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
}
.button1:focus {
  background-color: white;
  border-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
  color: #555;
}
.datey {
  font-size: 20px;
}
/* @media (max-width: 1000px) {
  .container22 {
    width: 95%;
    height: 95%;
    transform: translate(2.5%, 2.5%);
    flex-direction: column;
  }
  .boxx {
    width: 220%;
    height: 35%;
  }
  .right {
    margin-top: 38%;
  }
  .head {
    font-size: 580%;
  }
  .datey {
    font-size: 220%;
  }
  .button1 {
    width: 60%;
    height: 11%;
    font-size: 360%;
    padding-top: 1%;
    margin-top: 100px;
  }
} */
/* @media (min-width: 1800px) {
  .head {
    font-size: 60px;
  }
  .datey {
    font-size: 40px;
  }
} */
/* 
@media screen and (max-width: 1150px) {
  .pricecontainer {
    flex-grow: 1.8;
    margin: 30px 0 5px 0;
  }
}
@media screen and (max-width: 915px) {
  .container {
    flex-direction: column;
    padding: 10px;
  }
  .picture {
    margin: 10px;
  }
  .picture img {
    margin: 0;
  }
  .andarka {
    margin-top: 5px;
  }
  .pricecontainer {
    margin-bottom: 10px;
  }
} */
.pricecontainer button {
  outline: none;
  border: none;
}
@media screen and (max-width: 740px) {
  .mobile-nav .nav-button,
  .mobile-nav .nav-background {
    display: block;
  }
  .container-company .picture {
    min-width: 250px;
  }
  .container-company .andarka {
  }
}

ul.of {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.apcontainer {
  overflow: hidden;
  width: 100%;
}

.maincontainer2 {
  margin: 0%;
  width: 100%;
}

.head2 {
  font-size: 2.2em;
  margin: 0 auto;
}

.andarka2 {
  margin: 0px;
  padding: 0px;
}
