.id_page1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background: url("../Assets/Backgrounds/Drive.webp");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
}
.id_page2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 80vh;
  width: 100%;
  background: url("../Assets/Backgrounds/STATS.webp");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
}
.id_page3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  width: 100%;
  background: url("../Assets/Backgrounds/P6.webp");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
}

.drive_heading {
  font-weight: 800;
  font-size: 40px;
  text-align: center;
}

.drive_form {
  border: none;
  outline: none;
  margin: 10px 20px 10px 20px;
  font-size: 20px;
  width: 300px;
  padding: 10px 10px 10px 10px;
  background-color: transparent;
  box-shadow: 0px 0px 6px black;
  border-radius: 10px;
}

.drive_form:focus {
  box-shadow: 0px 0px 4px var(--color-3);
}

.drive_form::placeholder {
  font-size: 20px;
}

.drive_figures {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.register-button a {
  color: var(--color-2);
  text-decoration: none;
}
.register_head {
  font-size: 50px;
  text-align: center;
  padding: 25px;
  font-weight: 900;
  color: var(--color-2);
}

.stat_number {
  font-size: 40px;
  font-weight: 900;
  color: var(--color-1);
  text-align: center;
}

.stat_head {
  color: var(--color-1);
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}

.stat_block {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 20px;
  margin: 20px;
  min-width: 200px;
  background-color: var(--color-2);
}

.id_login,
.id_login:focus {
  border: none;
  outline: none;
  text-align: center;
  border-radius: 10px;
  text-decoration: none;
  font-weight: 500;
  font-size: 25px;
  background-color: var(--color-7);
  color: var(--color-2);
  transition: filter 0.3s ease-in-out;
}

.id_login:hover {
  filter: brightness(80%);
}

.company_plate {
  background-color: var(--color-2);
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 20px;
}

.com_name {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  font-family: "Montserrat", sans-serif;
}
.id_page4 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 60vh;
    width: 100%;
    background: url("../Assets//Backgrounds/xyz.webp");
    background-size: cover;
    background-attachment: fixed;

    background-repeat: no-repeat;
    padding: 100px;
}