@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Merienda:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,800);

body {
  padding: 0;
  margin: 0;
  --color-1: #000000;
  --color-2: #ffffff;
  --color-3: #08c2e9;
  --color-7: #3a82d7;
  --color-4: rgb(51, 51, 51);
  --color-5: rgb(125, 133, 93);
  --color-6: rgb(30, 30, 30);
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  font-family: "Merriweather", sans-serif;
}

.change_btn {
  border: none;
  outline: none;
  border-radius: 50%;
  width: 75px;
  height: 75px;
  font-size: 35px;
  cursor: pointer;
  -webkit-align-self: center;
          align-self: center;
  margin-left: -50px;
  background-color: var(--color-2);
}

.change_btn:hover {
  background-color: var(--color-1);
  opacity: 0.9;
  color: var(--color-2);
}

h1 {
  font-size: 40px;
  color: var(--color-2);
}

@-webkit-keyframes typewriter {
  0%,
  100% {
    width: 0;
  }
  20%,
  80% {
    width: 13.5em;
  }
}

@keyframes typewriter {
  0%,
  100% {
    width: 0;
  }
  20%,
  80% {
    width: 13.5em;
  }
}

@-webkit-keyframes caret {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes caret {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.text_1 {
  overflow: hidden;
  white-space: nowrap;
  -webkit-animation-name: typewriter;
          animation-name: typewriter;
  display: inline-block;
  position: relative;
  -webkit-animation-duration: 6s;
          animation-duration: 6s;
  margin-bottom: 20px;
  font-size: 35px;
  -webkit-animation-timing-function: steps(25, end);
          animation-timing-function: steps(25, end);
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.text_1::after {
  position: absolute;
  right: 0;
  -webkit-animation: caret infinite;
          animation: caret infinite;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-timing-function: steps(1, end);
          animation-timing-function: steps(1, end);
}

.page1_sub {
  color: var(--color-2);
  margin-top: -30px;
  width: 25em;
}
@media screen and (max-width: 525px) {
  .text_1 {
    font-size: 32px;
    display: block;
    line-height: 1;
    margin-bottom: 35px;
  }
}
@media screen and (max-width: 420px) {
  .text_1 {
    font-size: 28px;
  }
}

.gr_page1 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 100vh;
  width: 100%;
  background: url(/static/media/GR.3240bc25.webp);
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.9;
}
.gr_page4 {
  display: none;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 180vh;
  width: 100%;
  background: url(/static/media/About.b0331070.webp);
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
}
.gr_page5 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 5vh 0;
  width: 100%;
  background: url(/static/media/P3.c39d95ce.webp);
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
}
.gr_page2 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  height: 180vh;
  width: 100%;
  background: url(/static/media/P2.b24dedfb.webp);
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
}
.gr_page3 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding-top: 30px;
  padding-bottom: 30px;
  height: 100%;
  width: 100%;
  background: url(/static/media/Rules.8f4cd425.webp);
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
}

.reg_head {
  text-align: center;
  font-weight: 800;
  font-family: "Poppins", serif;
  font-size: 35px;
  margin-bottom: 30px;
}

.gr_input {
  border: none;
  outline: none;
  box-shadow: 0px 0px 3px var(--color-1);
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  width: 400px;
  -webkit-align-self: center;
          align-self: center;
}

.gr_input:focus {
  box-shadow: 0px 0px 4px var(--color-3);
}

.dots {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  background-color: var(--color-3);
  color: var(color-1);
  border: 3px solid var(--color-3);
}

.section_head {
  margin-top: 30px;
  color: var(--color-2);
  font-size: 20px;
}

.line {
  height: 5px;
  background-color: var(--color-3);
  width: 150px;
  -webkit-align-self: center;
          align-self: center;
  -webkit-animation: all 3s;
          animation: all 3s;
}

.book_next,
.book_next:focus {
  width: 110px;
  padding: 10px 20px;
  border-radius: 10px;
  margin-top: 30px;
  margin-left: 100px;
  background-color: var(--color-3);
  box-shadow: 0px 0px 6px var(--text-color-3);
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
}

.book_next:hover {
  box-shadow: 0px 0px 6px var(--color-1);
}

.btn_div {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  height: 100vh;
  background-image: url(/static/media/GR.f73bd93c.webp);
  background-size: cover;
}

.heading_main {
  color: var(--color-2);
  font-family: "Raleway", sans-serif;
  font-size: 62px;
  margin-bottom: 80px;
  font-weight: 600;
}

.btn,
.btn:focus {
  cursor: pointer;
  background-color: white;
  width: 300px;
  height: 70px;
  border-radius: 10px;
  font-size: 25px;
  font-weight: 600;
  background-size: 100% 100%;
  border: none;
  outline: none;
}

.btn:hover {
  opacity: 0.6;
}

.Description {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.Description1 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  -webkit-flex: 1 1;
          flex: 1 1;
  background-color: #00b4d8;
  border-radius: 60px;
  margin: 5px;
  box-shadow: 0px 0px 6px black;
}

.Description2 {
  display: -webkit-flex;
  display: flex;
  box-shadow: 0px 0px 6px black;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  -webkit-flex: 1 1;
          flex: 1 1;
  text-align: center;
  background-color: #caf0f8;
  border-radius: 60px;
  margin: 5px;
}

.dropbtn {
  border: none;
  outline: none;
  box-shadow: 0px 0px 3px var(--color-1);
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  width: 400px;
  -webkit-justify-content: center;
          justify-content: center;
  justify-self: center;
  background-color: transparent;
}

.inputfield {
  width: 500px;
}

.adding {
  background-color: var(--color-2);
}

.gr_head {
  font-weight: 600;
  margin-bottom: 10px;
  text-align: center;
}
.gr-info {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  background-color: white;
  opacity: 0.8;
  width: 450px;
  border-radius: 30px;
  margin-top: 30px;
  padding: 20px;
}
.gr-info div {
  line-height: 1.6;
  font-size: 20px;
}
.schema-faq-code {
  border: 1px solid #dedee0;
  border-radius: 10px;
  width: 532px;
  background-color: #fefbf9;
  overflow: hidden;
}
.schema-faq-code .faq-q {
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  padding: 12px 56px 12px 12px;
  line-height: 1.4;
  cursor: pointer;
  position: relative;
  border-bottom: 1px solid #dedee0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.faq-q:after {
  content: "+";
  position: absolute;
  top: 50%;
  right: 0;
  width: 56px;
  text-align: center;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-weight: bold;
  color: #000;
  font-size: 20px;
}
.faq-q.faq-q-open:after {
  content: "-";
}
.faq-a {
  margin: 0;
  padding: 12px;
  background-color: #fff;
  font-size: 14px;
  line-height: 1.4;
  border-bottom: 1px solid #dedee0;
  display: none;
}
.schema-faq-code .faq-question:last-child .faq-a {
  border-bottom: 0px;
}

.reg-closed {
  width: 100%;
  font-size: 50px;
}
@media screen and (max-width: 910px) {
  .gr-info {
    width: 80%;
  }
  .reg-closed {
    font-size: 30px;
  }
  .gr-info div {
    font-size: 19px;
  }
}
@media screen and (max-width: 625px) {
  .schema-faq-code {
    width: 90%;
  }
  .gr-info div {
    font-size: 18px;
  }
}
@media screen and (max-width: 525px) {
  .gr-info {
    width: 90%;
  }
  .reg-closed {
    font-size: 25px;
  }
  .gr-info div {
    font-size: 17px;
  }
}

.footer {
  background-color: var(--color-6);
  color: var(--color-2);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 50px 50px 100px 50px;
  font-size: 16px;
}

.footer_heading {
  font-size: 25px;
  font-weight: 700;
}

.fa-facebook,
.fa-twitter,
.fa-pinterest,
.fa-instagram {
  background: transparent;
  color: var(--color-1);
}

.fa1 {
  font-size: 25px;
  color: var(--color-2);
  width: 30px;
  height: 30px;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
  padding: 10px 10px 10px 10px;
  margin: 10px 10px 10px 10px;
}

.fa1:hover {
  color: var(--color-3);
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.footer_subnames {
  color: var(--color-5);
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.footer_subnames:hover {
  color: var(--color-3);
  text-decoration: none;
}

@media screen and (max-width: 725px) {
  .footer {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .spacing {
    margin-bottom: 100px;
  }
}

.se_page{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    height: 80vh;
    width: 100%;
    -webkit-filter: blur(10%);
            filter: blur(10%);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.se_page.p1{
    background: url(/static/media/valo.b2892f3a.jpg);
    background-position: center;
    background-size: cover;
    -webkit-filter: blur(10%);
            filter: blur(10%)
}
.se_page.p2{
    background: url(/static/media/quiz.dd60ee39.webp);
    background-position: center;
    background-size: cover;
}
.se_page.p3{
    background: url(/static/media/safl.a7afaad3.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}


.ct_page1 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  height: 200vh;
  width: 100%;
  background: url(/static/media/Team.f03c98a6.webp);
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
}

.contact1 {
  font-family: "Poppins", sans-serif;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  position: relative;
  top: 0;
  left: 0;
}
.contact-card {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-width: 200px;
  max-width: 240px;
  -webkit-align-items: center;
          align-items: center;
  margin: 20px;
}
.contact-card button i {
  margin-left: 3px;
}
.contact_icon {
  border: none;
  outline: none;
  -webkit-align-self: center;
          align-self: center;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  font-size: 35px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: var(--color-2);
  color: var(--color-1);
}

.contact_heading {
  font-weight: 700;
  font-size: 19px;
  margin: 25px 0px 25px 0px;
  text-align: center;
}

.contact_form {
  background-color: var(--color-3);
  padding: 50px;
  color: var(--color-2);
  width: 60%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.contactus_heading {
  font-size: 40px;
  font-weight: 800;
  text-align: center;
}

.head_image {
  width: 350px;
  height: 500px;
  margin: 50px;
}

.contact_dp {
    border-radius: 50%;
    width: 200px;
    height: 200px;
    -webkit-align-self: center;
            align-self: center;
}

.contact_name {
    font-size: 30px;
    text-align: center;
    font-weight: 800;
    color: var(--color-4);
}

.contact_post {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    color: var(--color-4);
}

.contact_number {
    text-align: center;
    font-size: 22px;
    color: var(--color-4);
}
.ec_page1 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    height: 100vh;
    width: 100%;
    background: url(/static/media/Econnect.ca8d6996.webp);
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
}
.ec_page2 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    height: 350vh;
    width: 100%;
    background: url(/static/media/P5.9149535d.webp);
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
}
.ec_page3 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    min-height: 140vh;
    width: 100%;
    background: url(/static/media/Perks.1a37dccf.webp);
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
}
.what_heading {
    font-size: 45px;
    font-weight: 700;
    margin: 50px;
    -webkit-align-self: center;
            align-self: center;
    font-family: "Merriweather", sans-serif;
}

.what_content {
    margin: 50px;
    width: 50%;
    font-size: 25px;
}

.vision_heading {
    font-size: 40px;
    font-weight: 700;
    margin: 50px;
    -webkit-align-self: center;
            align-self: center;
    color: var(--color-2);
    font-family: "Merriweather", sans-serif;
}

.vision_content {
    margin: 50px;
    width: 50%;
    font-size: 23px;
}

.connect_buttons {
    outline: none;
    border: none;
    font-size: 30px;
    background-color: var(--color-1);
    padding: 25px;
    color: var(--color-2);
    cursor: pointer;
}

.perk_icon {
    width: 200px;
    height: 200px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-self: center;
            align-self: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-content: center;
            align-content: center;
    
}

.perk_head {
    font-size: 25px;
    font-weight: 700;
    text-align: center;
    margin-top: 20px;
}

.perk_content {
    font-size: 22px;
    width: 350px;
}

.perk_template {
    margin: 20px;
    box-shadow:  0px 0px 6px rgb(0, 0, 0);
    border-radius: 10px;
    height: 275px;
    background-color: var(--color-2);
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    transition: all 0.8s ease;
}

.perk_template:hover{
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}
.front{
     /* -webkit-backface-visibility: hidden; */
     -webkit-backface-visibility: hidden;
             backface-visibility: hidden;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: column;
             flex-direction: column;
}
.back{
     /* -webkit-backface-visibility: hidden; */
     -webkit-backface-visibility: hidden;
             backface-visibility: hidden;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: column;
             flex-direction: column;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
    margin-top: -175px;
    padding: 5px;
    font-size: 20px;
}

.launch_events {
  border-radius: 15px;
  width: 300px;
  height: 250px;
}

.levents_heading {
  font-weight: 700;
  color: var(--color-2);
  text-align: center;
  font-size: 25px;
}

.boxes {
  margin: 45px;
}

@-webkit-keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    -webkit-transform: translatey(-20px);
            transform: translatey(-20px);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
}

@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    -webkit-transform: translatey(-20px);
            transform: translatey(-20px);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
}

.e-connect {
  box-sizing: border-box;
  overflow: hidden;
  -webkit-transform: translatey(0px);
          transform: translatey(0px);
  -webkit-animation: float 2.5s ease-in-out infinite;
          animation: float 2.5s ease-in-out infinite;
}
.e-connect:hover {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
@media screen and (max-width: 1030px) {
  .launch_events {
    height: 220px;
    width: 270px;
  }
}
@media screen and (max-width: 585px) {
  .launch_events {
    height: 210px;
    width: 265px;
  }
  .levents_heading {
    font-size: 24px;
  }
}

.title1 {
  position: relative;
  top: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 100vh;
  width: 100%;
  background: url(/static/media/Home.ac802997.webp);
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
}

.title2 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  min-height: 130vh;
  padding-bottom: 30px;
  width: 100%;
  background: url(/static/media/Black.4112e84f.webp);
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
}

.wrapper {
  position: absolute;
  left: 10vw;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 10px 10px 10px 0px;
  -webkit-justify-content: center;
          justify-content: center;
}

.home_headings {
  text-align: center;
  font-weight: 800;
  font-size: 40px;
  color: var(--color-4);
  margin-bottom: 40px;
}

.home_networking {
  background-color: var(--color-6);
  color: var(--color-2);
  width: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 55px 25px 120px 90px;
  margin-bottom: 100px;
}

.home_side_network {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 50%;
}

.net_title {
  font-size: 40px;
  font-weight: 800;
  color: var(--color-4);
  opacity: 1.6;
}

.network_btn,
.network_btn:focus {
  border: none;
  outline: none;
  background-color: var(--color-1);
  color: var(--color-2);
  width: -webkit-max-content;
  width: max-content;
  padding: 25px;
  margin-top: 50px;
  font-size: 25px;
  cursor: pointer;
  border-radius: 20px;
}

.network_btn:hover {
  box-shadow: 0px 0px 6px black;
}

.net_image {
  width: 100%;
  height: auto;
}

.initiative {
  border-radius: 10px;
  width: 200px;
  height: 200px;
  margin: 100px 100px 5px 100px;
  cursor: pointer;
}

@media screen and (max-width: 1225px) {
  .initiative {
    width: 150px;
    height: 150px;
  }
}

@media screen and (max-width: 725px) {
  .title2 {
    height: auto;
  }
  .wrapper,
  .page1_sub {
    width: 20em;
  }
}
@media screen and (max-width: 525px) {
  .wrapper {
    left: 5vw;
  }
}

.events_pic {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    -webkit-align-self: center;
            align-self: center;
    margin-bottom: 40px;
}

.events_h_name {
    text-align: center;
    color: var(--color-4);
    font-weight: 800;
    font-size: 20px;
}

.events_description {
    text-align: center;
    color: var(--color-3);
    font-weight: 600;
}
.guest_image {
    border-radius: 50%;
    width: 75px;
    height: 75px;
}

.guest_name {
    font-weight: 700;
    color: var(--color-2);
    font-size: 19px;
}

.guest_post {
    font-weight: 500;
    width: 80%;
    color: var(--color-2);
    opacity: 0.6;
}
.id_page1 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 100vh;
  width: 100%;
  background: url(/static/media/Drive.e1eadd75.webp);
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
}
.id_page2 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  height: 80vh;
  width: 100%;
  background: url(/static/media/STATS.48a5dd4f.webp);
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
}
.id_page3 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  height: auto;
  width: 100%;
  background: url(/static/media/P6.877574a6.webp);
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
}

.drive_heading {
  font-weight: 800;
  font-size: 40px;
  text-align: center;
}

.drive_form {
  border: none;
  outline: none;
  margin: 10px 20px 10px 20px;
  font-size: 20px;
  width: 300px;
  padding: 10px 10px 10px 10px;
  background-color: transparent;
  box-shadow: 0px 0px 6px black;
  border-radius: 10px;
}

.drive_form:focus {
  box-shadow: 0px 0px 4px var(--color-3);
}

.drive_form::-webkit-input-placeholder {
  font-size: 20px;
}

.drive_form:-ms-input-placeholder {
  font-size: 20px;
}

.drive_form::placeholder {
  font-size: 20px;
}

.drive_figures {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.register-button a {
  color: var(--color-2);
  text-decoration: none;
}
.register_head {
  font-size: 50px;
  text-align: center;
  padding: 25px;
  font-weight: 900;
  color: var(--color-2);
}

.stat_number {
  font-size: 40px;
  font-weight: 900;
  color: var(--color-1);
  text-align: center;
}

.stat_head {
  color: var(--color-1);
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}

.stat_block {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 10px;
  border-radius: 20px;
  margin: 20px;
  min-width: 200px;
  background-color: var(--color-2);
}

.id_login,
.id_login:focus {
  border: none;
  outline: none;
  text-align: center;
  border-radius: 10px;
  text-decoration: none;
  font-weight: 500;
  font-size: 25px;
  background-color: var(--color-7);
  color: var(--color-2);
  transition: -webkit-filter 0.3s ease-in-out;
  transition: filter 0.3s ease-in-out;
  transition: filter 0.3s ease-in-out, -webkit-filter 0.3s ease-in-out;
}

.id_login:hover {
  -webkit-filter: brightness(80%);
          filter: brightness(80%);
}

.company_plate {
  background-color: var(--color-2);
  padding: 10px;
  border-radius: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin: 20px;
}

.com_name {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  font-family: "Montserrat", sans-serif;
}
.id_page4 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    min-height: 60vh;
    width: 100%;
    background: url(/static/media/xyz.a2ab85b5.webp);
    background-size: cover;
    background-attachment: fixed;

    background-repeat: no-repeat;
    padding: 100px;
}
.lp_page1 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    height: 100vh;
    width: 100%;
    background: url(/static/media/Summit.7f0166bf.webp);
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
}
.lp_page2 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    height: auto;
    width: 100%;
    background: url(/static/media/Black.4112e84f.webp);
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
}
.lp_page8 {
    display: -webkit-flex;
    display: flex;
    height: 200vh;
    width: 140%;
    background: url(/static/media/S1.58800e00.webp);
    background-repeat: no-repeat;
}

.logo_fly {
    width: 100%;
    height: auto;
}

.guest_heading {
    color: var(--color-2);
    font-weight: 700;
    font-size: 45px;
    padding-top: 100px;
}

.sub_heading {
    color: var(--color-1);
    font-size: 30px;
    font-weight: 700;
}

.sponsor_options {
    margin: 20px;
    cursor: pointer;
    font-size: 40px;
}

.sponsor_head {
    text-align: center;
    font-weight: 600;
    font-size: 40px;
}

.sponsor_name {
    text-align: center;
    font-weight: 500;
    font-size: 30px;
}

.sponsor_image {
    width: 400px;
    height: 400px;
    -webkit-align-self: center;
            align-self: center;
    -webkit-align-content: center;
            align-content: center;
    text-align: center;
}

.sp_p1 {
    position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 100vh;
    width: 100%;
    background: url(/static/media/B3.05af0a06.webp);
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    -webkit-animation: change 40s infinite alternate;
            animation: change 40s infinite alternate;
}
.sp_p2 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items:center;
            align-items:center;
    min-height: 100vh;
    padding-bottom: 30px;
    width: 100%;
    background: url("/static/media/SP 1.91a13f23.webp");
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
  }
  .sp_p3 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items:center;
            align-items:center;
    min-height: 100vh;
    padding-bottom: 30px;
    width: 100%;
    background: url(/static/media/ConferenceSpeaker.3a93df5e.webp);
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
  }

  @-webkit-keyframes change {
    0% {
        background: url(/static/media/B1.6955241a.webp);
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
    }
    25% {
        background: url(/static/media/B2.53c43073.webp);
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
    }
    50% {
        background: url(/static/media/B3.05af0a06.webp);
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
    }
    75% {
        background: url(/static/media/B4.a764c326.webp);
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
    }
    100% {
        background: url(/static/media/B2.53c43073.webp);
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
    }
}

  @keyframes change {
    0% {
        background: url(/static/media/B1.6955241a.webp);
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
    }
    25% {
        background: url(/static/media/B2.53c43073.webp);
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
    }
    50% {
        background: url(/static/media/B3.05af0a06.webp);
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
    }
    75% {
        background: url(/static/media/B4.a764c326.webp);
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
    }
    100% {
        background: url(/static/media/B2.53c43073.webp);
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
    }
}
.reg_head{
    color:white;
    font-family: "Roboto, sans-serif" ;
    background-color: black ;
    padding:20px ;
    border-radius:20px ;
    margin:15px;
}
p{
    background-color: rgba(0, 0, 139, 0.5);
    font-family:Georgia, 'Times New Roman', Times, serif;
}
.multi_head {
    width: 50%;
    font-size: 40px;
    text-align: center;
    font-weight: 700;
    font-family: "Merriweather", sans-serif;
}

.navigate {
    font-size: 33px;
    font-weight: 700;
    color: var(--color-1);
    text-decoration: none;
    text-align: right;
}
.sidenav {
  width: 40vw;
  z-index: 20;
  background-color: black;
  position: fixed;
  right: 0;
  height: 100%;
  margin-right: 10px;
  margin: 0;
  overflow-x: hidden;
  padding-top: 60px;
}

.logostyle {
  width: 120%;
  margin-left: -30px;
  height: 100px;
}

.namestyle {
  font-size: 30px;
}

.slink {
  padding: 5px;
  margin: 5px;
  text-decoration: none;
  list-style-type: none;
  width: -webkit-max-content;
  width: max-content;
  font-size: 25px;
  color: #818181;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  transition: 0.3s;
}

.sidelinks:hover {
  color: #f1f1f1;
  text-decoration: none;
}

.closebtn {
  position: absolute;
  top: 20px;
  right: 25px;
  font-size: 30px;
  margin-left: 50px;
  cursor: pointer;
}

.side_button {
  margin: 20px;
  font-size: 30px;
  cursor: pointer;
  z-index: 3;
  width: -webkit-max-content;
  width: max-content;
}

.appearance {
  display: none;
  width: 36px;
}

@media screen and (max-width: 860px) {
  .appearance {
    display: block;
  }
}

@media screen and (max-width: 525px) {
  .sidenav {
    width: 70%;
  }
  .logostyle {
    height: 120px;
  }
}

@media screen and (min-width: 525px) {
  .namestyle,
  .logostyle {
    display: none;
  }
}

@media screen and (max-width: 475px) {
  .namestyle {
    font-size: 20px;
  }
  .logostyle {
    height: 90px;
  }
}

.bar {
  list-style: none;
  text-align: right;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  margin: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  height: 90px;
  z-index: 1;
  transition: background-color 0.3s ease-in-out;
}

.name {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  margin-left: 110px;
  margin-top: 15px;
  font-size: 175%;
  width: auto;
  font-weight: 900;
  font-family: "Poppins", sans-serif;
  color: var(--color-2);
  z-index: 2;
}

.logo {
  position: fixed;
  top: 5px;
  left: -30px;
  width: 275px;
  height: 100px;
  z-index: 2;
}

.link {
  text-decoration: none;
  padding: none;
  margin: none;
  color: white;
  display: inline-block;
  cursor: pointer;
  font-size: 20px;
  transition: color 0.5s ease-in-out;
}

.link:hover,
.linkInternship:hover {
  color: var(--color-3);
  text-decoration: none;
}
.linkInternship {
  margin: 25px;
  background: var(--color-3);
  border-radius: 20px;
  font-size: 20px;
  padding: 0px 10px 0px 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  transition: all 0.5s ease-in-out;
  text-decoration: none;
  color: white;
}
.linkInternship:hover {
  background-color: #fff;
}

.linkInternship::after {
  display: none !important;
}
.main {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.navWrapper {
  display: -webkit-flex;
  display: flex;
  position: relative;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}
.navWrapper div {
  position: relative;
}
.link:not(.borderline)::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -10px;
  left: 0;
  height: 3px;
  background-color: var(--color-3);
  z-index: 2;
  width: 0%;
  transition: 0.3s ease-in;
}

.borderline:not(.slink)::after {
  /* border-bottom: 3px solid var(--color-3); */
  content: "";
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  height: 3px;
  background-color: var(--color-3);
  z-index: 2;
  width: 100%;
}

@media screen and (max-width: 860px) {
  .link {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .link {
    margin: 25px 5px 5px 5px;
    font-size: 17px;
  }
}

@media (max-width: 525px) {
  .logo {
    display: none;
  }
  .name {
    display: none;
  }
  .linkInternship {
    position: absolute;
    height: 40px;
    left: 10px;
    margin: 25px;
    font-size: 20px;
    background: var(--color-3);
    border-radius: 20px;
    padding: 0px 10px 0px 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    transition: all 0.5s ease-in-out;
    text-decoration: none;
    color: white;
  }
}

.blog_thumbnail {
    margin: 30px;
    width: 350px;
    height: 350px;
}
.gm_1 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    height: 100vh;
    width: 100%;
    background: url(/static/media/klh1.f385a5a5.jpg);
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
}
.fb_live{
    display: none;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
    height:100vh;
    background: url(/static/media/hgj.c637a69f.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding:50px;
}
.rules {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    height:-webkit-fit-content;
    height:-moz-fit-content;
    height:fit-content;
    background: url(/static/media/lpo.85f8c327.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding:50px;
}
.rules_head {
    font-size: 30px;
    font-family: 'Roboto';
    font-weight: 900;
    color: rgb(0, 16, 109);
    padding:20px;
    border-radius: 5px;
}
.rules_content {
    color: white;
    padding:20px;
}
.h {
    font-size: 25px;
    color: red;
    font-weight: 800;
}
.w {
    color: var(--color-3);
    font-size: 15px;
}

.spon {
    font-size: 25px;
    font-weight: 900;
    color: red;
}
.schedule {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    height: auto;
    width: 100%;
    padding:10px;
    background-image: url(/static/media/xyz.a2ab85b5.webp);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
}
.sched_head {
  font-weight: 800;
  font-size: 40px;
  padding: 10px;
  background-color: rgba(240, 248, 255);
  margin-bottom:20px;
  margin-top: 10px;
  border-radius: 10px;
}
.tables {
    margin:40px;
    background-color: #002754;
}
tr {
    border: 2px solid white;
}
td , th {
    padding:10px;
    text-align: center;
}
th {
    font-size: 30px;
    padding:20px;
    color:#082cea;
    font-family: Roboto;
}
.t {
   
    margin-bottom: 20px;
}
td {
    color: white;
    font-size: 20px;
    font-weight: 700;
}
.thumbnail {
    width: 400px;
    height: 300px;
}

.session_head {
    text-align: center;
    font-size: 25px;
    font-weight: 800;
}
.qz_1 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    height: 100vh;
    width: 100%;
    background: url(/static/media/kooiz.0e9b59bc.jpeg);
    -webkit-filter: blur(70%);
            filter: blur(70%);
    -webkit-filter: brightness(90%);
            filter: brightness(90%);
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
}
.text_1.heading{
    color:rgb(0, 0, 0);
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: rgb(185, 178, 247);
    font-weight: bolder;
}
.fb_live{
    display: none; /*convert none to flex when streaming*/
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
    height:100vh;
    background: url(/static/media/kuiz.fa32bf48.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding:50px;
}
.live {
    background-color: rgba(240, 248, 255, 0.822);
    font-size: 30px;
    font-family: 'Roboto';
    font-weight: 900;
    color: rgb(0, 16, 109);
    padding:20px;
    border-radius: 5px;
}
html{
    scroll-behavior: smooth;
}
.bp_page1 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    height: 100vh;
    width: 100%;
    background: url(/static/media/GR.3240bc25.webp);
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
}
.heading_1{
    -webkit-flex: 0 1;
            flex: 0 1;
    text-align: center;
    font-size: 100px;
    color:#08c2e9;
}
.reg{
    -webkit-align-self: center;
            align-self: center;
    -webkit-align-items: center;
            align-items: center;
}
.container{
    background-image: linear-gradient(to bottom right, #005999,#002551);
    border-radius: 200px 200px 30px 30px;
}

.reg-closed {
    width: 100%;
    font-size: 50px;
}

@media screen and (max-width: 1225px) {
    .reg-closed {
      width: 100%;
      font-size: 50px;
      
    }
}
@media screen and (max-width: 725px) {
    .reg-closed {
        width: 100%;
        font-size: 30px;
       
    }
}
@media screen and (max-width: 525px) {
    .reg-closed {
       width: 100%;
       font-size: 25px;
       
    }
}

.se_page1 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    height: 100vh;
    width: 100%;
    background: url(/static/media/startup.9536c3a7.webp);
    -webkit-filter: blur(70%);
            filter: blur(70%);
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
}
.sp_page1 {
        position: relative;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        -webkit-flex-direction: column;
                flex-direction: column;
        padding-top: 30px;
        padding-bottom: 30px;
        height: 100vh;
        width: 100%;
        background-image: url(/static/media/Sponsor.f49a2f9e.webp);
        background-size: cover;
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
}

.sp_page2 {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content:center;
                justify-content:center;
        -webkit-align-items:center;
                align-items:center;
        min-height: 100vh;
        padding-bottom: 30px;
        width: 100%;
        background: url("/static/media/SP 1.91a13f23.webp");
        background-size: cover;
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
}
.sp_page3 {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content:center;
                justify-content:center;
        -webkit-align-items:center;
                align-items:center;
        min-height: 100vh;
        padding-bottom: 30px;
        width: 100%;
        background: url(/static/media/a2.26149b17.jfif);
        background-size: cover;
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
}
.cm_pg1 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 100vh;
  width: 100%;
  background: url(/static/media/CNM.b723548b.jfif);
  -webkit-filter: brightness(70%);
          filter: brightness(70%);
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
}

.cm_pg2 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 120vh;
  width: 100%;
  -webkit-filter: blur(50%);
          filter: blur(50%);
  background: url("/static/media/CNM 2.2326b8ec.webp");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
}

.C_join,
.C_join:focus {
  border: none;
  outline: none;
}
.id_login {
  background-color: var(--color-1);
  margin: 0px;
  position: relative;
  z-index: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 15px 20px;
}
.id_login a {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0;
  z-index: 10;
  mix-blend-mode: difference;
}
.id_login::after {
  content: "";
  background-color: white;
  border-radius: 10px;
  height: 100%;
  width: 0;
  padding: 0;
  margin: 0;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.5s;
}
.id_login:hover {
  -webkit-filter: brightness(100%) !important;
          filter: brightness(100%) !important;
  color: black;
}
.id_login:hover::after {
  width: 100%;
}

.lg_page1 {
  display: -webkit-flex;
  display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 120vh;
    width: 100%;
    background: url(/static/media/kkk.8b050462.jpeg);
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
}

h1 {
  font-weight: bold;
  margin: 0;
}

h2 {
  text-align: center;
}

p {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}

span {
  font-size: 12px;
}

a {
  color: #333;
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0;
}

button {
  border-radius: 20px;
  border: 1px solid #ff4b2b;
  background-color: #ff4b2b;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: -webkit-transform 80ms ease-in;
  transition: transform 80ms ease-in;
  transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
}

button:active {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

button:focus {
  outline: none;
}

button.ghost {
  background-color: transparent;
  border-color: #ffffff;
}

form {
  background-color: #ffffff;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
}

input,
input:focus {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
  outline: none;
}

.container {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  z-index: 0;
  width: 768px;
  max-width: 100%;
  min-height: 540px;
}
#container {
  margin: 13% auto;
  margin-bottom: 0px;
}
.form-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.sign-in-container {
  left: 0;
  width: 50%;
  z-index: 2;
}

.container.right-panel-active .sign-in-container {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.sign-up-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
  overflow: auto;
}

.container.right-panel-active .sign-up-container {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  -webkit-animation: show 0.6s;
          animation: show 0.6s;
}

@-webkit-keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

@keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
  z-index: 100;
}

.container.right-panel-active .overlay-container {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.overlay {
  background: #ff416c;
  background: -webkit-linear-gradient(to right, #ff4b2b, #ff416c);
  background-image: radial-gradient(
    circle,
    #5b5454,
    #504a4a,
    #453f3f,
    #3b3636,
    #312c2c
  );

  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

.container.right-panel-active .overlay {
  -webkit-transform: translateX(50%);
          transform: translateX(50%);
}

.overlay-panel {
  position: absolute;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

.overlay-left {
  -webkit-transform: translateX(-20%);
          transform: translateX(-20%);
}

.container.right-panel-active .overlay-left {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.overlay-right {
  right: 0;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.container.right-panel-active .overlay-right {
  -webkit-transform: translateX(20%);
          transform: translateX(20%);
}

.social-container {
  margin: 20px 0;
}

.social-container a {
  border: 1px solid #dddddd;
  border-radius: 50%;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
}

footer {
  background-color: #222;
  color: #fff;
  font-size: 14px;
  bottom: 0;
  position: fixed;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 999;
}

footer p {
  margin: 10px 0;
}

footer i {
  color: red;
}

footer a {
  color: #3c97bf;
  text-decoration: none;
}
.headx {
  font-size: 25px;
  margin-bottom: 20px;
  font-weight: bold;
  -webkit-align-self: center;
          align-self: center;
  margin-top: 15px;
}
.signin {
  font-size: 25px;
  margin-bottom: 20px;
  font-weight: bold;
}
.button2 {
  margin-top: 20px;
  transition: 0.2s;
  background-color: #444;
  border-color: #555;
  margin-bottom: 10px;
}
.button2:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
}

.button2:focus {
  background-color: white;
  border-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
  color: #555;
}
input[type="file"] {
  display: none;
}
label {
  width: 284px;
  background-color: #f1eeee;
  height: 35px;
  padding: 9px;
  font-size: 14px;
  font-weight: 100;
  margin-top: 8px;
  color: #777;
  text-align: left;
  padding-left: 15px;
  border-radius: 2px;
}
.error{
  color: red;
  background-color: transparent;
}
.forgotpw{
  background-color: transparent;
  color: red;
}
.forgotpw:hover{
  text-decoration: underline;
  cursor: pointer; 
}
.scrollmsg1,.scrollmsg2,.scrollmsg3,.scrollmsg4{
  background-color: transparent;
  color: red;
}
.scrollmsg1{
  -webkit-transform: translate(50%,-700%);
          transform: translate(50%,-700%);
}
.scrollmsg2{
  -webkit-transform: translate(-50%,-600%);
          transform: translate(-50%,-600%);
}
.scrollmsg3{
  -webkit-transform: translate(-50%,-15%);
          transform: translate(-50%,-15%);
}
.scrollmsg4{
  -webkit-transform: translate(50%,-100%);
          transform: translate(50%,-100%);
}
@media (max-width:550px){
  .container{
    -webkit-transform: scale(.75);
            transform: scale(.75);
    overflow: auto;
  }
  .form-container{
    width: 100%;
    padding-top: 0;
  }
  .overlay-container{ 
    width: 100%;
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
  }
  .container.right-panel-active .overlay-container {
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  .container.right-panel-active .overlay {
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
  }
}
@media (min-width:550px)
{
  .scrollmsg1,.scrollmsg2,.scrollmsg3,.scrollmsg4{
    display: none;
  }
}
.container1 {
  width: 290px;
  height: 100%;
  margin: 10px;
  margin-left: 0px;
  margin-top: 0px;
  background-image: linear-gradient(
    to bottom,
    #5b5454,
    #504a4a,
    #453f3f,
    #3b3636,
    #312c2c
  );
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.maincontainer {
  display: -webkit-flex;
  display: flex;
  margin: 50px;
  background-image: linear-gradient(
    to bottom,
    #ffffff,
    #ebebec,
    #d9d7d8,
    #c6c4c5,
    #b4b1b1
  );
  border-radius: 10px;
  height: 85vh;
  padding-top: 0px;
  padding-left: 0px;
}
.inner {
  width: 100%;
  font-size: 0px;
  font-weight: 100;
  font-family: "Poppins", sans-serif;
  color: white;
}
.internshipheader {
  font-size: 22px;
  padding: 13px;
  font-weight: 500;
  color: white;
  text-align: center;
  border-style: solid;
  border-bottom-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  border-color: white;
  margin-left: 15px;
  margin-right: 15px;
  opacity: 1;
  letter-spacing: 5px;
}

.dashboard {
  height: 40px;
  margin: 20px;
  margin-top: 5px;
  transition: 0.25s;
}
.myprofile {
  height: 40px;
  margin: 20px;
  transition: 0.25s;
}

.buttonac {
  font-size: 15px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  background: none;
  border: none;
  color: white;
  width: 95%;
  height: 50px;
  transition: 0.25s;
  outline: none;
  border-radius: 30px;
  margin: 5px;
  text-align: center;
}
.nav-contents button {
  border: 1px solid rgba(255, 255, 255, 0.3);
  width: 200px;
}
.buttonac:focus {
  background-color: white;
  border: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.6), 0 3px 10px 0 rgba(0, 0, 0, 0.6);
  color: black;
}
.buttonac:hover {
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
}
.nav-contents button:hover {
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.4),
    0 6px 20px 0 rgba(15, 15, 15, 0.4);
}
/* Companies List */

.container2 {
  overflow: auto;
  width: 100%;
}
.andarka,
.picture,
.pricecontainer {
  min-width: 227px;
}
.andarka {
  -webkit-flex: 1 1;
          flex: 1 1;
  margin: -10px 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  padding-left: 2rem;
  -webkit-justify-content: center;
          justify-content: center;
}

.picture,
.pricecontainer {
  -webkit-flex-grow: 0.8;
          flex-grow: 0.8;
  -webkit-flex-basis: 0;
          flex-basis: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.picture img {
  margin-left: 10px;
  width: 100%;
  height: auto;
}

ul {
  padding: 0px;
  width: 100%;
}

li.container {
  display: -webkit-flex;
  display: flex;
  height: auto;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  padding: 20px 10px;
  margin: 30px;
  background-image: radial-gradient(
    circle,
    #5b5454,
    #504a4a,
    #453f3f,
    #3b3636,
    #312c2c
  );
}
.head {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 39px;
  font-weight: 700;
  /* margin-left: 40px;
  margin-right: 20px;
  margin-top: 5px;
  margin-bottom: 0px; */
  color: white;
}
.subhead {
  margin-top: -10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: white;
  font-size: 1.2rem;
}
.table {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1.8rem;
  margin-top: 20px;
  font-weight: 400;
  color: white;
}

.cart {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-flex-direction: column !important;
          flex-direction: column !important;
  -webkit-justify-content: center !important;
          justify-content: center !important;
  background-color: rgb(60, 179, 113) !important;
  border-radius: 1.8rem !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
  width: -webkit-max-content !important;
  width: max-content !important;
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  padding: 8px 15px !important;
  text-align: center !important;
  color: white !important;
  font-size: 18px !important;
  font-weight: 650 !important;
  transition: 0.3s !important;
  margin: 0px !important;
}
.cart:hover {
  cursor: pointer;
  background-color: darkgreen;
  color: white;
}

@media screen and (max-width: 1150px) {
  .pricecontainer {
    -webkit-flex-grow: 1.8;
            flex-grow: 1.8;
    margin: 30px 0 5px 0;
  }
}
@media screen and (max-width: 915px) {
  .container {
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 10px;
  }
  .picture {
    margin: 10px;
  }
  .picture img {
    margin: 0;
  }
  .andarka {
    margin-top: 5px;
  }
  .pricecontainer {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 740px) {
  .mobile-nav .nav-button,
  .mobile-nav .nav-background {
    display: block;
  }
  .container1 {
    display: none;
  }
  .maincontainer {
    height: 99vh;
    width: 95vw;
    margin: auto;
    margin-top: 30px;
    padding-bottom: 30px;
  }
  /* .andarka,
  .picture,
  .pricecontainer {
    min-width: 0;
  } */
}
/* width */
::-webkit-scrollbar {
  width: 10px;
  overflow: hidden;
}

/* Track */
::-webkit-scrollbar-track {
  background-image: linear-gradient(
    to bottom,
    #ffffff,
    #ebebec,
    #d9d7d8,
    #c6c4c5,
    #b4b1b1
  );
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 10px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.container-company {
  -webkit-justify-content: center;
          justify-content: center;
  display: -webkit-flex;
  display: flex;
  height: auto;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 40%), 0 6px 20px 0 rgb(0 0 0 / 40%);
  border-radius: 10px;
  padding: 20px 10px;
  margin: 30px;
  background-image: radial-gradient(
    circle,
    #5b5454,
    #504a4a,
    #453f3f,
    #3b3636,
    #312c2c
  );
}

.id_back {
  background-image: url("/static/media/ID back.4127eed5.webp");
  background-size: cover;
  width: 100%;
  height: 100vh;
}

.maincontainer {
  background-image: linear-gradient(
    to bottom,
    #ffffff,
    #ebebec,
    #d9d7d8,
    #c6c4c5,
    #b4b1b1
  );
  border-radius: 10px;
  height: 85%;
  width: 90%;
  display: -webkit-flex;
  display: flex;
  padding-left: 0px;
}

.container1 {
  width: 230px;
  height: 100%;
  margin-left: 0px;
  margin-top: 0px;
  background-image: linear-gradient(
    to bottom,
    #5b5454,
    #504a4a,
    #453f3f,
    #3b3636,
    #312c2c
  );
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.inner {
  width: 100%;
  font-size: 18px;
  font-weight: 100;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: white;
}

.ecell {
  font-size: 22px;
  padding: 13px;
  font-weight: 500;
  color: white;
  text-align: center;
  border-style: solid;
  border-bottom-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  border-color: white;
  margin-left: 15px;
  margin-right: 15px;
  opacity: 1;
}

.dashboard {
  height: 40px;
  margin: 20px;
  margin-top: 5px;
  transition: 0.25s;
}

.myprofile {
  height: 40px;
  margin: 20px;
  transition: 0.25s;
}

.Appliedcompanies {
  height: 40px;
  margin: 20px;
  transition: 0.25s;
}

.Viewcompany {
  height: 40px;
  margin: 20px;
  transition: 0.25s;
}

.paynow {
  height: 40px;
  margin: 20px;
  transition: 0.25s;
}

.button,
.button:focus {
  font-size: 16px;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background: none;
  border: none;
  color: white;
  width: 100%;
  height: 50px;
  transition: 0.25s;
  outline: none;
  border-radius: 30px;
  text-align: center;
}

.button:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
}

.button_click {
  outline: none;
  background-color: white;
  border: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.6), 0 3px 10px 0 rgba(0, 0, 0, 0.6);
  color: black;
}

.container {
  display: -webkit-flex;
  display: flex;
  width: 875px;
  height: 215px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  padding: 10px;
  margin: 30px;
  background-image: radial-gradient(
    circle,
    #5b5454,
    #504a4a,
    #453f3f,
    #3b3636,
    #312c2c
  );
}

.head {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 30px;
  font-weight: 700;
  color: white;
}

.subhead {
  margin-left: 40px;
  margin-right: 12px;
  margin-top: 0px;
  margin-bottom: 8px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: white;
  font-size: 12px;
}

.table {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin-left: 30px;
  margin-right: 0px;
  margin-top: 10px;
  margin-bottom: 0px;
  padding: 5px;
  padding-right: 0px;
  font-size: 18px;
  font-weight: 400;
  color: white;
}

.cart {
  background-color: rgb(60, 179, 113);
  border-radius: 18px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  width: 175px;
  height: 40px;
  text-align: center;
  padding: auto;
  color: white;
  font-size: 20px;
  margin-left: 30px;
  margin-right: 20px;
  margin-top: 75px;
  margin-bottom: 0px;
  font-weight: 650;
  transition: 0.15s;
  padding-top: 5px;
  cursor: pointer;
}

.cart:hover {
  background-color: darkgreen;
  color: white;
}

::-webkit-scrollbar {
  width: 10px;
  overflow: hidden;
}

/* Track */

::-webkit-scrollbar-track {
  background-image: linear-gradient(
    to bottom,
    #ffffff,
    #ebebec,
    #d9d7d8,
    #c6c4c5,
    #b4b1b1
  );
  border-radius: 10px;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 10px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.top {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 40px;
  border-style: solid;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  border-bottom-width: 1px;
  margin: 20px;
  margin-left: 100px;
  margin-right: 100px;

  padding-bottom: 20px;
  text-align: center;
}
.container2 {
  display: -webkit-flex;
  display: flex;
  height: 100%;
  width: 100%;
  overflow: auto;
}
.left {
  width: 40%;
  margin: 5%;
  text-align: center;
  margin-left: 6%;
  margin-right: 3%;
}
.right {
  width: 40%;
  margin: 5%;
  text-align: center;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
  overflow: hidden;
}

/* Track */
::-webkit-scrollbar-track {
  background-image: linear-gradient(
    to bottom,
    #ffffff,
    #ebebec,
    #d9d7d8,
    #c6c4c5,
    #b4b1b1
  );
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 10px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.box {
  background-image: radial-gradient(
    circle,
    #5b5454,
    #504a4a,
    #453f3f,
    #3b3636,
    #312c2c
  );
  margin-bottom: 30px;
  border-radius: 10px;
  padding: 10px;
  padding-bottom: 15px;
}
.andarka {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  font-weight: 500;
  color: white;
}
.head {
  font-size: 25px;
  margin-bottom: 12px;
}
.date {
  font-size: 25px;
}

.app_button {
  width: 150px;
  background-color: darkgreen;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  border-radius: 20px;
  -webkit-transform: translate(75%, 0px);
          transform: translate(75%, 0px);
  margin-top: 15px;
  height: 37px;
  padding: 5px;
  color: white;
}
.tag {
  color: white;
  border: 1px solid white;
  padding: 0px 10px;
  font-size: 15px;
  border-radius: 15px;
  text-align: center;
  margin-bottom: 5px;
  font-family: "Montserrat", sans-serif;
}

.tag-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.container1 {
  width: 290px;
  height: 100%;
  margin: 10px;
  margin-left: 0px;
  margin-top: 0px;
  background-image: linear-gradient(
    to bottom,
    #5b5454,
    #504a4a,
    #453f3f,
    #3b3636,
    #312c2c
  );
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.maincontainer {
  display: -webkit-flex;
  display: flex;
  margin: 0 auto;
  background-image: linear-gradient(
    to bottom,
    #ffffff,
    #ebebec,
    #d9d7d8,
    #c6c4c5,
    #b4b1b1
  );
  border-radius: 10px;
  height: 85vh;
  padding-top: 0px;
  padding-left: 0px;
}
.inner {
  width: 100%;
  font-weight: 100;
  font-family: "Poppins", sans-serif;
  color: white;
}
.ecell {
  font-size: 22px;
  padding: 13px;
  font-weight: 500;
  color: white;
  text-align: center;
  border-style: solid;
  border-bottom-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  border-color: white;
  margin-left: 15px;
  margin-right: 15px;
  opacity: 1;
  letter-spacing: 5px;
}

.dashboard {
  height: 40px;
  margin: 20px;
  margin-top: 5px;
  transition: 0.25s;
}
.myprofile {
  height: 40px;
  margin: 20px;

  transition: 0.25s;
}

.button {
  font-size: 15px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  background: none;
  border: none;
  color: white;
  width: 95%;
  transition: 0.25s;
  outline: none;
  border-radius: 30px;
  margin: 5px;
  text-align: center;
}
.nav-contents button {
  border: 1px solid rgba(255, 255, 255, 0.3);
  width: 200px;
}
.button:focus {
  background-color: white;
  border: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.6), 0 3px 10px 0 rgba(0, 0, 0, 0.6);
  color: black;
}
.button:hover {
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
}
.nav-contents button:hover {
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.4),
    0 6px 20px 0 rgba(255, 255, 255, 0.4);
}
/* Companies List */

.container2 {
  overflow: auto;
  width: 100%;
  margin-bottom: 20px;
}
.andarka,
.picture,
.pricecontainer {
  min-width: 227px;
}
.andarka {
  -webkit-flex: 1 1;
          flex: 1 1;
  margin: -10px 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  padding-left: 2rem;
}

.picture,
.pricecontainer {
  -webkit-flex-grow: 0.8;
          flex-grow: 0.8;
  -webkit-flex-basis: 0;
          flex-basis: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.picture img {
  margin-left: 10px;
  width: 100%;
  height: auto;
}
.container {
  display: -webkit-flex;
  display: flex;
  height: auto;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  padding: 20px 10px;
  margin: 30px;
  background-image: radial-gradient(
    circle,
    #5b5454,
    #504a4a,
    #453f3f,
    #3b3636,
    #312c2c
  );
}
.head {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 39px;
  font-weight: 700;
  /* margin-left: 40px;
  margin-right: 20px;
  margin-top: 5px;
  margin-bottom: 0px; */
  color: white;
}
.subhead {
  margin-top: -10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: white;
  font-size: 1.2rem;
}
.table {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1.8rem;
  margin-top: 20px;
  font-weight: 400;
  color: white;
}

.cart {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: rgb(60, 179, 113);
  border-radius: 1.8rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  width: 200px;
  height: 50px;
  text-align: center;
  color: white;
  font-size: 2rem;
  font-weight: 650;
  transition: 0.3s;
}
.cart:hover {
  cursor: pointer;
  background-color: darkgreen;
  color: white;
}

.mobile-nav .nav-checkbox {
  display: none;
}

.mobile-nav .nav-button {
  display: none;
  text-align: center;
  background: red;
  height: 5.4rem;
  width: 5.4rem;
  border-radius: 50%;
  position: fixed;
  top: 3.4rem;
  right: 3rem;
  z-index: 2000;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.4);
}
.nav-button i {
  font-size: 2.4rem;
}
.mobile-nav .nav-background {
  display: none;
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.9);
  position: fixed;
  top: 3.5rem;
  right: 3.1rem;
  z-index: 1000;
  transition: -webkit-transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 0.8s cubic-bezier(0.86, 0, 0.07, 1), -webkit-transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
}
.mobile-nav .nav-contents {
  text-align: center;
  height: 100vh;
  opacity: 0;
  transition: opacity 1.7s;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1500;
}
.mobile-nav .nav-contents .nav-contents-wrapper {
  width: 0;
}
.nav-button i,
.mobile-nav .nav-contents .nav-contents-wrapper,
.mobile-nav .navigation-list {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.mobile-nav .nav-checkbox:checked ~ .nav-background {
  -webkit-transform: scale(80);
          transform: scale(80);
}
.mobile-nav .nav-checkbox:checked ~ .nav-contents {
  width: 100vw;
  display: block;
  opacity: 1;
}
.mobile-nav .nav-checkbox:checked ~ .nav-contents .nav-contents-wrapper {
  width: 100vw;
}

@media screen and (max-width: 1150px) {
  .pricecontainer {
    -webkit-flex-grow: 1.8;
            flex-grow: 1.8;
    margin: 30px 0 5px 0;
  }
}
@media screen and (max-width: 915px) {
  .container {
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 10px;
  }
  .picture {
    margin: 10px;
  }
  .picture img {
    margin: 0;
  }
  .andarka {
    margin-top: 5px;
  }
  .pricecontainer {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 740px) {
  .mobile-nav .nav-button,
  .mobile-nav .nav-background {
    display: block;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 10px;
    -webkit-transition: 0.2s;
    transition: 0.2s;
  }

  /* .andarka,
.picture,
.pricecontainer {
  min-width: 0;
} */
}
/* width */
::-webkit-scrollbar {
  width: 10px;
  overflow: hidden;
}

.andarka {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  font-weight: 500;
  color: white;
}
.head {
  font-size: 25px;
  margin-bottom: 12px;
}
.date {
  font-size: 25px;
}

.app_button {
  width: 150px;
  background-color: darkgreen;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  border-radius: 20px;
  -webkit-transform: translate(75%, 0px);
          transform: translate(75%, 0px);
  margin-top: 15px;
  height: 37px;
  padding: 5px;
  color: white;
}
.tag {
  color: white;
  border: 1px solid white;
  padding: 0px 17px;
  border-radius: 15px;
  text-align: center;
  margin-bottom: 5px;
}

.tag-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

  .newmaincontainer {
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 40%), 0 6px 20px 0 rgb(0 0 0 / 40%);
    width: 95%;
    max-height: 90%;
    margin-top: 20px;
    margin-left: 20px;
    display: -webkit-flex;
    display: flex;
    z-index: 0;
    -webkit-flex-direction: column;
            flex-direction: column;
  } 
  .newuppercontainer {
    background-image: radial-gradient(circle, #5b5454, #504a4a, #453f3f, #3b3636, #312c2c);
    border-radius: 10px;
    height: 45%;
    width: 100%;
    z-index: 2;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-basis: 180px;
            flex-basis: 180px;
    -webkit-justify-content: center;
            justify-content: center;
    }
  .lowercontainer{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    overflow: auto;
  }
  .newimage {
    -webkit-align-self: center;
            align-self: center;
    margin-right: 10px;
    margin : 3%;
    color: white;
    height: 125px;
    width: 125px;
    border-radius: 50%;    
    
    
    background-size: cover;
    background-position: center;
  }
  .rightimage {
    margin-left: 2%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
}
  .newname {
    font-size: 250%;
    font-weight: 800;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: white;
}
  .newdesignation {
    margin-top: 1%;
    font-size: 110%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 600;
    color: #999;
  }
  .lowerbox {
    padding: 20px;
    z-index: 1;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    /* overflow: auto; */
    width: 90%;
    margin-top: -10px;
    margin-bottom: 10px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 40%), 0 6px 20px 0 rgb(0 0 0 / 40%);
    background-color: white;
    /* transform: translateY(-5%); */
    border-radius: 5px;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
      
  }
  
  .namehead {
    margin: 5px;
    margin-left: 7px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    color: #999;
    font-weight: 750;
    font-size: 17px;
  }
  .info-card{
      padding: 5px;
      -webkit-flex-basis: 12;
              flex-basis: 12;
  }
  .boxy {
    width: 100%;
    height: 40px;
    text-decoration: none;
    border-width: 0px;
    border-radius: 4px;
    text-align: center;
    outline: none;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 19px;
    background-color: none;
    font-weight: 700;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 5px;
  }  

  @media(max-width: 400px) 
  {
    .newimage{
      height: 100px;
      width: 100px;
    }
    .newname{
      font-size: 180%;
    }
  }

.container1 {
  overflow: auto;
  min-width: 278px;
  height: 100%;
  margin: 0 !important;
  margin-left: 0px;
  margin-top: 0px;
  background-image: linear-gradient(
    to bottom,
    #5b5454,
    #504a4a,
    #453f3f,
    #3b3636,
    #312c2c
  );
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.maincontainer {
  margin: 50px;
  background-image: linear-gradient(
    to bottom,
    #ffffff,
    #ebebec,
    #d9d7d8,
    #c6c4c5,
    #b4b1b1
  );
  border-radius: 10px;
  height: 85%;
  padding-top: 0px;
  padding-left: 0px;
  overflow: hidden;
}
.inner {
  width: 100%;
  font-size: 0px;
  font-weight: 100;
  font-family: "Poppins", sans-serif;
  color: white;
}
.internshipheader {
  font-size: 22px;
  padding: 13px;
  font-weight: 500;
  color: white;
  text-align: center;
  border-style: solid;
  border-bottom-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  border-color: white;
  margin-left: 15px;
  margin-right: 15px;
  opacity: 1;
  letter-spacing: 5px;
}

.dashboard {
  height: 40px;
  margin: 20px;
  margin-top: 5px;
  transition: 0.25s;
}
.myprofile {
  height: 40px;
  margin: 20px;

  transition: 0.25s;
}

.buttonac {
  font-size: 15px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  background: none;
  border: none;
  color: white;
  width: 95%;
  height: 50px;
  transition: 0.25s;
  outline: none;
  border-radius: 30px;
  margin: 5px;
  text-align: center;
}
.buttonac:focus {
  background-color: white;
  border: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.6), 0 3px 10px 0 rgba(0, 0, 0, 0.6);
  color: black;
}
.buttonac:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
}
/* width */
::-webkit-scrollbar {
  width: 10px;
  overflow: hidden;
}

/* Track */
::-webkit-scrollbar-track {
  background-image: linear-gradient(
    to bottom,
    #ffffff,
    #ebebec,
    #d9d7d8,
    #c6c4c5,
    #b4b1b1
  );
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 10px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.sidebar-button {
  margin: 15px 10px;
}
.sidebar-button button {
  padding: 2px;
}
.maincontainer {
  display: -webkit-flex;
  display: flex;
}
.container2 {
  overflow: auto;
  width: 100%;
}
.container {
  display: -webkit-flex;
  display: flex;
  width: -100px;
  height: 200px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  padding: 10px;
  margin: 30px;
  background-image: radial-gradient(
    circle,
    #5b5454,
    #504a4a,
    #453f3f,
    #3b3636,
    #312c2c
  );
}
.head {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 39px;
  font-weight: 700;
  margin-left: 40px;
  margin-right: 20px;
  margin-top: 5px;
  margin-bottom: 0px;
  color: white;
}
.subhead {
  margin-left: 40px;
  margin-right: 12px;
  margin-top: 0px;
  margin-bottom: 8px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: white;
  font-size: 12px;
}
.table {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin-left: 30px;
  margin-right: 0px;
  margin-top: 10px;
  margin-bottom: 0px;
  padding: 5px;
  padding-right: 0px;
  font-size: 18px;
  font-weight: 400;
  color: white;
}

.cart {
  background-color: rgb(60, 179, 113);
  border-radius: 18px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  width: 200px;
  height: 40px;
  text-align: center;
  padding: auto;
  color: white;
  font-size: 20px;
  margin-left: 10px;
  margin-right: 20px;
  margin-top: 75px;
  margin-bottom: 0px;
  font-weight: 650;
  transition: 0.15s;
  padding-top: 10px;
}
.cart:hover {
  background-color: darkgreen;
  color: white;
}

@media screen and (max-width: 915px) {
  .container {
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 10px;
  }
  .picture {
    margin: 10px;
  }
  .picture img {
    margin: 0;
  }
  .andarka {
    margin-top: 5px;
  }
  .pricecontainer {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 740px) {
  .mobile-nav .nav-button,
  .mobile-nav .nav-background {
    display: block;
  }
  .container1 {
    display: none;
  }
  .maincontainer {
    height: 99vh;
    width: 95vw;
    margin: 30px auto;
    padding-bottom: 30px;
  }
  /* .andarka,
  .picture,
  .pricecontainer {
    min-width: 0;
  } */
}

/* Mobile Nav */

.mobile-nav .nav-checkbox {
  display: none;
}

.mobile-nav .nav-button {
  display: none;
  text-align: center;
  background: red;
  height: 54px;
  width: 54px;
  border-radius: 50%;
  position: fixed;
  top: 34px;
  right: 30px;
  z-index: 2000;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.4);
}
.nav-button i {
  font-size: 24px;
}
.mobile-nav .nav-background {
  display: none;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.9);
  position: fixed;
  top: 42px;
  right: 31px;
  z-index: 1000;
  transition: -webkit-transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 0.8s cubic-bezier(0.86, 0, 0.07, 1), -webkit-transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
}
.mobile-nav .nav-contents {
  text-align: center;
  height: 100vh;
  opacity: 0;
  transition: opacity 1.7s;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1500;
}
.mobile-nav .nav-contents .nav-contents-wrapper {
  width: 0;
}
.nav-button i,
.mobile-nav .nav-contents .nav-contents-wrapper,
.mobile-nav .navigation-list {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.mobile-nav .nav-checkbox:checked ~ .nav-background {
  -webkit-transform: scale(80);
          transform: scale(80);
}
.mobile-nav .nav-checkbox:checked ~ .nav-contents {
  width: 100vw;
  display: block;
  opacity: 1;
}
.mobile-nav .nav-checkbox:checked ~ .nav-contents .nav-contents-wrapper {
  width: 100vw;
}
.top {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  /* font-size: ; */
  border-style: solid;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  border-bottom-width: 1px;
  margin: 20px;
  margin-left: 100px;
  margin-right: 100px;

  padding-bottom: 20px;
  text-align: center;
}
.container22 {
  width: 95%;
  margin: 20px auto;
  background-image: linear-gradient(
    to bottom,
    #ffffff,
    #ebebec,
    #d9d7d8,
    #c6c4c5,
    #b4b1b1
  );
  display: -webkit-flex;
  display: flex;
  height: 95%;
  overflow: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
  padding-top: -20px;
}
.lefty {
  width: 40%;
  margin: 5%;
  text-align: center;
  margin-left: 6%;
  margin-right: 3%;
}
.right {
  width: 40%;
  margin: 5%;
  text-align: center;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
  overflow: hidden;
}

/* Track */
::-webkit-scrollbar-track {
  background-image: linear-gradient(
    to bottom,
    #ffffff,
    #ebebec,
    #d9d7d8,
    #c6c4c5,
    #b4b1b1
  );
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 10px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.boxx {
  max-width: 350px;
  background-image: radial-gradient(
    circle,
    #5b5454,
    #504a4a,
    #453f3f,
    #3b3636,
    #312c2c
  );
  margin-bottom: 30px;
  border-radius: 10px;
  padding: 10px;
  padding-bottom: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  margin: 10px;
}
.andarka {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  color: white;
}
.head {
  font-size: 30px;
  margin-bottom: 12px;
}
.button1 {
  width: 150px;
  background-color: darkgreen;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 18px;
  border-radius: 20px;
  margin-top: 15px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 9px;
  font-weight: 650;
  color: white;
  transition: 0.2s;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border-color: #555;
}
.button1:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
}
.button1:focus {
  background-color: white;
  border-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
  color: #555;
}
.datey {
  font-size: 20px;
}
/* @media (max-width: 1000px) {
  .container22 {
    width: 95%;
    height: 95%;
    transform: translate(2.5%, 2.5%);
    flex-direction: column;
  }
  .boxx {
    width: 220%;
    height: 35%;
  }
  .right {
    margin-top: 38%;
  }
  .head {
    font-size: 580%;
  }
  .datey {
    font-size: 220%;
  }
  .button1 {
    width: 60%;
    height: 11%;
    font-size: 360%;
    padding-top: 1%;
    margin-top: 100px;
  }
} */
/* @media (min-width: 1800px) {
  .head {
    font-size: 60px;
  }
  .datey {
    font-size: 40px;
  }
} */
/* 
@media screen and (max-width: 1150px) {
  .pricecontainer {
    flex-grow: 1.8;
    margin: 30px 0 5px 0;
  }
}
@media screen and (max-width: 915px) {
  .container {
    flex-direction: column;
    padding: 10px;
  }
  .picture {
    margin: 10px;
  }
  .picture img {
    margin: 0;
  }
  .andarka {
    margin-top: 5px;
  }
  .pricecontainer {
    margin-bottom: 10px;
  }
} */
.pricecontainer button {
  outline: none;
  border: none;
}
@media screen and (max-width: 740px) {
  .mobile-nav .nav-button,
  .mobile-nav .nav-background {
    display: block;
  }
  .container-company .picture {
    min-width: 250px;
  }
  .container-company .andarka {
  }
}

ul.of {
  overflow: hidden;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}
.apcontainer {
  overflow: hidden;
  width: 100%;
}

.maincontainer2 {
  margin: 0%;
  width: 100%;
}

.head2 {
  font-size: 2.2em;
  margin: 0 auto;
}

.andarka2 {
  margin: 0px;
  padding: 0px;
}


.outercontainer{
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
    height: 90%;
    width: 90%;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.uppercontainer{
    background-image: radial-gradient(circle, #5b5454, #504a4a, #453f3f, #3b3636, #312c2c);
    border-radius: 10px;
    width: 100%;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    -webkit-flex-basis: 180px;
            flex-basis: 180px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}
.image{
    margin-top:2%;
    margin-right: 2%;
    height: 125px;
    width: 125px;
    border-radius: 75px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
    
    background-size: cover;
}   
.names{
    margin-left: 2%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
}
.namenew{
    font-size: 250%;
    font-weight: 800;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: white;
}
.namenewlow{
    margin-top: 1%;
    font-size: 110%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 600;
    color: #999;
}
.cl1{
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    overflow: auto;
}
.boxnew{
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 20%);
    padding: 10px;
    margin: 10px;
    /* width: 50%; */
    min-height: 166px;
    background-color: white;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 180%;
    font-weight: 800;
    color: black;
    border-radius: 10px;
    -webkit-flex-basis: 4;
            flex-basis: 4;
    width: 30%;
    max-width: 287px;
    min-width: 200px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
}
.titlenew {
    text-align: center;
    font-size: 30px;
}
.number{
    background-color: #999;
    -webkit-align-self: center;
            align-self: center;
    text-align: center;
    border-radius: 35px;
    padding: 0px 30px;
    margin: 10px 0 5px 0;
}
.baaki{
    margin-top: 5px;
    -webkit-transform: translateY(-15%);
            transform: translateY(-15%);
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 170%;
    font-weight: 800;
}
.note {
    padding: 5px;
}
.note_head {

   color: red;
   font-size: 20px; 
   font-weight: 800;
   text-decoration: underline;
}
.note_content {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    }
.points {
   font-weight: 600;
   color:rgb(0, 48, 119);
   margin:2px;
}
@media(max-width: 740px){
    .boxnew{
        max-width:280px;
    }
}

.ws_1 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    height: 100vh;
    width: 100%;
    background: url(/static/media/PM.0a73af55.jpg);
    -webkit-filter: blur(70%);
            filter: blur(70%);
    -webkit-filter: brightness(90%);
            filter: brightness(90%);
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
}
.form_back {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
    background: url(/static/media/FB.80f101a2.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding:50px;
}
.ws_2 {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
@media ( max-width: 1000px) {
    .ws_2 {
        -webkit-flex-direction: column;
                flex-direction: column;
    }
    .x1 , .x2 {
        width: 100%;
    }
}
