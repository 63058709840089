html{
    scroll-behavior: smooth;
}
.bp_page1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background: url("../../Assets/Backgrounds/GR.webp");
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
}
.heading_1{
    flex: 0;
    text-align: center;
    font-size: 100px;
    color:#08c2e9;
}
.reg{
    align-self: center;
    align-items: center;
}
.container{
    background-image: linear-gradient(to bottom right, #005999,#002551);
    border-radius: 200px 200px 30px 30px;
}

.reg-closed {
    width: 100%;
    font-size: 50px;
}

@media screen and (max-width: 1225px) {
    .reg-closed {
      width: 100%;
      font-size: 50px;
      
    }
}
@media screen and (max-width: 725px) {
    .reg-closed {
        width: 100%;
        font-size: 30px;
       
    }
}
@media screen and (max-width: 525px) {
    .reg-closed {
       width: 100%;
       font-size: 25px;
       
    }
}
