.container1 {
  overflow: auto;
  min-width: 278px;
  height: 100%;
  margin: 0 !important;
  margin-left: 0px;
  margin-top: 0px;
  background-image: linear-gradient(
    to bottom,
    #5b5454,
    #504a4a,
    #453f3f,
    #3b3636,
    #312c2c
  );
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.maincontainer {
  margin: 50px;
  background-image: linear-gradient(
    to bottom,
    #ffffff,
    #ebebec,
    #d9d7d8,
    #c6c4c5,
    #b4b1b1
  );
  border-radius: 10px;
  height: 85%;
  padding-top: 0px;
  padding-left: 0px;
  overflow: hidden;
}
.inner {
  width: 100%;
  font-size: 0px;
  font-weight: 100;
  font-family: "Poppins", sans-serif;
  color: white;
}
.internshipheader {
  font-size: 22px;
  padding: 13px;
  font-weight: 500;
  color: white;
  text-align: center;
  border-style: solid;
  border-bottom-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  border-color: white;
  margin-left: 15px;
  margin-right: 15px;
  opacity: 1;
  letter-spacing: 5px;
}

.dashboard {
  height: 40px;
  margin: 20px;
  margin-top: 5px;
  transition: 0.25s;
}
.myprofile {
  height: 40px;
  margin: 20px;

  transition: 0.25s;
}

.buttonac {
  font-size: 15px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  background: none;
  border: none;
  color: white;
  width: 95%;
  height: 50px;
  transition: 0.25s;
  outline: none;
  border-radius: 30px;
  margin: 5px;
  text-align: center;
}
.buttonac:focus {
  background-color: white;
  border: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.6), 0 3px 10px 0 rgba(0, 0, 0, 0.6);
  color: black;
}
.buttonac:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
}
/* width */
::-webkit-scrollbar {
  width: 10px;
  overflow: hidden;
}

/* Track */
::-webkit-scrollbar-track {
  background-image: linear-gradient(
    to bottom,
    #ffffff,
    #ebebec,
    #d9d7d8,
    #c6c4c5,
    #b4b1b1
  );
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 10px;
  transition: 0.2s;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.sidebar-button {
  margin: 15px 10px;
}
.sidebar-button button {
  padding: 2px;
}
.maincontainer {
  display: flex;
}
.container2 {
  overflow: auto;
  width: 100%;
}
.container {
  display: flex;
  width: -100px;
  height: 200px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  padding: 10px;
  margin: 30px;
  background-image: radial-gradient(
    circle,
    #5b5454,
    #504a4a,
    #453f3f,
    #3b3636,
    #312c2c
  );
}
.head {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 39px;
  font-weight: 700;
  margin-left: 40px;
  margin-right: 20px;
  margin-top: 5px;
  margin-bottom: 0px;
  color: white;
}
.subhead {
  margin-left: 40px;
  margin-right: 12px;
  margin-top: 0px;
  margin-bottom: 8px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: white;
  font-size: 12px;
}
.table {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin-left: 30px;
  margin-right: 0px;
  margin-top: 10px;
  margin-bottom: 0px;
  padding: 5px;
  padding-right: 0px;
  font-size: 18px;
  font-weight: 400;
  color: white;
}

.cart {
  background-color: rgb(60, 179, 113);
  border-radius: 18px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  width: 200px;
  height: 40px;
  text-align: center;
  padding: auto;
  color: white;
  font-size: 20px;
  margin-left: 10px;
  margin-right: 20px;
  margin-top: 75px;
  margin-bottom: 0px;
  font-weight: 650;
  transition: 0.15s;
  padding-top: 10px;
}
.cart:hover {
  background-color: darkgreen;
  color: white;
}

@media screen and (max-width: 915px) {
  .container {
    flex-direction: column;
    padding: 10px;
  }
  .picture {
    margin: 10px;
  }
  .picture img {
    margin: 0;
  }
  .andarka {
    margin-top: 5px;
  }
  .pricecontainer {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 740px) {
  .mobile-nav .nav-button,
  .mobile-nav .nav-background {
    display: block;
  }
  .container1 {
    display: none;
  }
  .maincontainer {
    height: 99vh;
    width: 95vw;
    margin: 30px auto;
    padding-bottom: 30px;
  }
  /* .andarka,
  .picture,
  .pricecontainer {
    min-width: 0;
  } */
}
